// import package
import React, { useEffect, Fragment } from 'react';
import { Button } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// import component
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from 'react-i18next';

// import config
import config from '../../config';

// import lib
import { toastAlert } from '../../lib/toastAlert';

const SellerDetails = (props) => {
    
    const history = useHistory();
    const { t, i18n } = useTranslation();

    // props
    const { detail } = props;

    const bankDetails = () => {
       return (
            <ul className='detailsBuyerSeller'>
            {
                detail && 
                detail.transferDetails && 
                detail.transferDetails.holderName &&
                detail.transferDetails.accountNo && 
                detail.transferDetails.bankName &&
                detail.transferDetails.bankcode &&
                detail.transferDetails.city &&
                detail.transferDetails.country && (
                    <Fragment>
                        <li>
                            <label>Name</label>
                            <span>{detail.transferDetails.holderName}</span>
                        </li>
                        <li>
                            <label>Account Number</label>
                            <span>{detail.transferDetails.accountNo}</span>
                            <CopyToClipboard
                                text={detail.transferDetails.accountNo}
                                onCopy={() => { toastAlert("success", "Copied!", 'accountNo') }}
                            >
                                <a href='#' className='ml-2'><i class="far fa-copy"></i></a>
                            </CopyToClipboard>
                            
                        </li>
                        <li>
                            <label>Bank Name</label>
                            <span>{detail.transferDetails.bankName}</span>
                        </li>
                        <li>
                            <label>IFSC Code</label>
                            <span>{detail.transferDetails.bankcode}</span>
                        </li>
                        <li>
                            <label>Bank Branch</label>
                            <span>{`${detail.transferDetails.city}, ${detail.transferDetails.country}`}</span>
                        </li>
                    </Fragment>
                )
            }
            </ul>
       )
    }

    const upiDetails = () => {
       return (
            <ul className='detailsBuyerSeller'>
                <li>
                    <label>UPI ID</label>
                    {
                        detail && detail.transferDetails && detail.transferDetails.upiId && (
                            <Fragment>
                                <span>{detail.transferDetails.upiId}</span>
                                <CopyToClipboard
                                    text={detail.transferDetails.upiId}
                                    onCopy={() => { toastAlert("success", "Copied!", 'upiId') }}
                                >
                                    <a href='#' className='ml-2'><i class="far fa-copy"></i></a>
                                </CopyToClipboard>
                            </Fragment>
                        )
                    }
                    
                </li>
            </ul>
       )
    }

    const gpayDetails = () => {
       return (
            <ul className='detailsBuyerSeller'>
            {
                detail && detail.transferDetails && detail.transferDetails.frontImage && (
                    <li>
                        <label>Gpay QR Image</label>
                        <span><img src={`${config.API_URL}/images/qr/${detail.transferDetails.frontImage}`} height="200" width="200"/></span>
                    </li>
                )
            }
            </ul>
       )
    }

    return (
        <GridItem xs={12} sm={12} md={12} lg={4}>
            <div className="note_section_">
                <div>
                    <p>Seller Details</p>
                    { detail && detail.payBy == 'bank' && bankDetails() }
                    { detail && detail.payBy == 'upi' && upiDetails() }
                    { detail && detail.payBy == 'gpay' && gpayDetails() }
                    <p>{t('NOTES')}</p>
                    <ol>
                        <li>{t('CHAT_ONLY_TRADE')} </li>
                        <li> {t('AVOID_WORNG_WORDS')}</li>
                        <li>{t('TRANSFER_PAYMENT_BUTTON')}</li>

                    </ol>
                </div>

                <div className="support_section">
                    <p>{t('NEED_SUPPORT')}</p>
                    <Button onClick={() => history.push('/support-ticket')}>{t('CREAT_SUPPORT_TICKET')}</Button>
                </div>

            </div>
        </GridItem>
    )
}

export default SellerDetails;