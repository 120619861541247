// import package
import React, { createRef, useState, useEffect } from 'react';
import { Button } from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import clsx from 'classnames';
import { useTranslation } from 'react-i18next';
// import action
import { resetPin, checkTokenVerify } from '../../actions/users';

// import lib
import validation from './validation';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
    'pinNumber': '',
    'confirmPinNumber': '',
    'showPin': false,
    'showConfirmPin': false,
}

const ResetPin = () => {
    const history = useHistory();
    const { authToken } = useParams();
    const { t, i18n } = useTranslation();
    // states
    const [formValue, setFormValue] = useState(initialFormValue);
    const [toched, setToched] = useState({});
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();

    const { pinNumber, confirmPinNumber, showPin, showConfirmPin } = formValue;

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        if (name == 'pinNumber' || name == 'confirmPinNumber') {
            if (!(value == '' || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
                return
            }
        }

        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        setValidateError(validation(formData))
    }

    const handleBlur = (e) => {
        const { name } = e.target;
        setToched({ ...toched, ...{ [name]: true } })
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        let reqData = {
            pinNumber,
            confirmPinNumber,
            authToken
        }
        let { status, loading, error, message } = await resetPin(reqData);
        setLoader(loading);
        if (status == "success") {
            setFormValue(initialFormValue)
            setToched({})
            setValidateError(validation(initialFormValue))
            history.push("/login");
            toastAlert('success', message, 'resetPin');
        } else {
            if (error) {
                setValidateError(error);
            }
            toastAlert('error', message, 'resetPin');

        }
    }

    const checkToken = async () => {
        let { status, message } = await checkTokenVerify({ authToken });

        if(status == 'failed'){
            toastAlert('error',t(message),'checkTokenVerify')
            history.push("/login");
        }
    }

    useEffect(() => {
        checkToken()
        setValidateError(validation(formValue))
    }, [])

    return (
        <div className="login_container">
            {/* <h2 className="text-center mb-md-4 pb-3" data-aos="fade-up">Reset Password</h2> */}
            <div className="row w-100">
                <div className="col-lg-4 col-md-6 m-auto">
                    <form className="login_form p-4 mb-4" data-aos="fade-up">
                        <h3 className="login_title_8">{t('PIN_RESET')}</h3>
                        {/* <p className="paraLabel text-center mb-3">Input your registered email address, we’ll send you reset password.</p> */}

                        <div className="form-group">
                            <span className="login_label">{t('NEW_PIN')}</span>
                            <div className="input-group regGroupInput mt-2">
                                <input
                                    className="form-control"
                                    placeholder={t('NEW_PIN')}
                                    name="pinNumber"
                                    type={showPin ? "text" : "password"}
                                    value={pinNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div className="input-group-append">
                                    <Link onClick={(e) => {
                                        e.preventDefault();
                                        setFormValue((el => {
                                            return { ...el, ...{ showPin: !el.showPin } }
                                        }))
                                    }}>
                                        <i className={clsx("fa", { "fa-eye": showPin }, { "fa-eye-slash": !showPin })} aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                            {toched.pinNumber && validateError.pinNumber && <p className="error-message">{t(validateError.pinNumber)}</p>}
                        </div>
                        <div className="form-group">
                            <span className="login_label">{t('CONFIRM_PIN')}</span>
                            <div className="input-group regGroupInput mt-2">
                                <input
                                    className="form-control"
                                    placeholder={t('CONFIRM_PIN')}
                                    name="confirmPinNumber"
                                    type={showConfirmPin ? "text" : "password"}
                                    value={confirmPinNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div className="input-group-append">
                                    <Link onClick={(e) => {
                                        e.preventDefault();
                                        setFormValue((el => {
                                            return { ...el, ...{ showConfirmPin: !el.showConfirmPin } }
                                        }))
                                    }}>
                                        <i className={clsx("fa", { "fa-eye": showConfirmPin }, { "fa-eye-slash": !showConfirmPin })} aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                            {toched.confirmPinNumber && validateError.confirmPinNumber && <p className="error-message">{t(validateError.confirmPinNumber)}</p>}
                        </div>

                        <div className="form-group">
                            <Button
                                onClick={handleFormSubmit}
                                disabled={!isEmpty(validateError)}
                            >
                                {loader && <i class="fas fa-spinner fa-spin"></i>}{t('Submit')}
                            </Button>
                        </div>
                        {/* <div className="d-flex">
                            <Link to="/login" className="ml-auto">Login</Link>
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPin;