import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { MenuItem, Select } from '@material-ui/core';

// import Actions
import { getInstantPair, orderPlace } from '../../actions/instantAction';

// import lib
import { toFixed } from '../../lib/roundOf';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';


const initialFormValue = {
    'firstCurrencyId': '',
    'secondCurrencyId': '',
    'firstCurrencySymbol': '',
    'secondCurrencySymbol': '',
    'amount': 0,
}

const InstantOrder = (props) => {

    // hooks
    const dispatch = useDispatch();

    // props
    const { listRef } = props;

    // state
    const [formValue, setFormValue] = useState(initialFormValue)
    // const [pairList, setPairList] = useState([]);
    const [firstCoinList, setFirstCoinList] = useState([]);
    const [secondCoinList, setSecondCoinList] = useState([]);
    const [usrBalance, setUsrBalance] = useState(0);
    const [fee, setFee] = useState(0);
    const [marketPrice, setMarketPrice] = useState(0);
    const [receiveAmount, setReceiveAmount] = useState(0);
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [isSwap, setIsSwap] = useState(false);
    // const [coin, setCoin] = useState('');

    const { firstCurrencyId, secondCurrencyId, firstCurrencySymbol, secondCurrencySymbol, amount } = formValue;

    // redux-state
    const walletData = useSelector((state) => state.wallet);
    const priceCNV = useSelector((state) => state.priceConversion);
    const { pairList, firstCoinList: firstCoin, secondCoinList : secondCoin } = useSelector((state) => state.instantPair);

    const getPair = async () => {
        // const { status, result } = await getInstantPair();

        // if (status == 'success') {
        //     if (result) {
        //         setFirstCoinList(result.firstCoin)
        //         setSecondCoinList(result.secondCoin)
        //         setPairList(result.data)
        //     }
        // }

    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        if (['XRP', 'DOGE', 'CTC', 'BNP', 'BUSD'].includes(value)) {
            toastAlert('error', `${value} coming soon`)
            return
        }
        let formData = {};
        if (['firstCurrencyId', 'secondCurrencyId'].includes(name)) {
            let obj = pairList.find((el) => el[!isSwap ? name : (name == 'firstCurrencyId') ? 'secondCurrencyId' : 'firstCurrencyId'] == value);
            let symbol = name.replace('Id', 'Symbol');
            formData = { ...formValue, ...{ [name]: value, [symbol]: obj[!isSwap ? symbol : (symbol == 'firstCurrencySymbol') ? 'secondCurrencySymbol' : 'firstCurrencySymbol'] } }
        } else {
            formData = { ...formValue, ...{ [name]: value } }
        }
        console.log(formData,'formDataformData')
        setFormValue(formData)
        setErrors({})
    }

    const handleSwap = () => {
        setIsSwap(!isSwap) 
        setFirstCoinList(secondCoinList)
        setSecondCoinList(firstCoinList)
        setFormValue((el) => {
            return { 
                ...el , 
                ...{ 
                    'firstCurrencyId' : el.secondCurrencyId, 
                    'secondCurrencyId' : el.firstCurrencyId, 
                    'firstCurrencySymbol' : el.secondCurrencySymbol, 
                    'secondCurrencySymbol' : el.firstCurrencySymbol, 
                } 
            }
        })
        if(!isSwap){ 

            if (pairList && pairList.length > 0) {
                let findDoc = pairList.find((el) => ((el.firstCurrencyId == firstCurrencyId) && (el.secondCurrencyId == secondCurrencyId)));
                if (findDoc) {
                    setFee(findDoc.fee)
                    setMarketPrice(1 / findDoc.marketPrice)
                }
            }
        }
    }

    const handleSubmit = async () => {
        setLoader(true);
        let data = {
            firstCurrencyId,
            secondCurrencyId,
            amount,
            isSwap
        }
        // console.log(data, 'data')
        let { status, message, error } = await orderPlace(data, dispatch);

        if (status == 'success') {
            toastAlert('success', message, 'orderPlace')
            setFormValue({
                ...formValue,
                ...{
                    'amount': 0
                }
            })
            setReceiveAmount(0)
            setErrors({})
            setLoader(false);
            listRef.current.listData()
        } else {
            setLoader(false);
            if (message) {
                toastAlert('error', message, 'orderPlace')
                return
            }
            setErrors(error)
        }
    }

    useEffect(() => {
        if (firstCurrencyId != '' && secondCurrencyId != '') {
            if (pairList && pairList.length > 0) {
                let findDoc = pairList.find((el) => ((el[!isSwap ? 'firstCurrencyId' : 'secondCurrencyId'] == firstCurrencyId) && (el[!isSwap ? 'secondCurrencyId' : 'firstCurrencyId'] == secondCurrencyId)));
                if (findDoc) {
                    setFee(findDoc.fee)
                    setMarketPrice(!isSwap ? findDoc.marketPrice : 1 / findDoc.marketPrice )
                }
            }
        }
    }, [isSwap, firstCurrencyId, secondCurrencyId])

    useEffect(() => {
        if (!isEmpty(firstCurrencyId) && !isEmpty(secondCurrencyId) && !isEmpty(amount) && !isEmpty(marketPrice)) {
            if (amount > 0) {
                setReceiveAmount(amount * marketPrice);
            } else {
                setReceiveAmount(0);
            }
        } else if (isEmpty(amount)) {
            setReceiveAmount(0);
        }
    }, [firstCurrencyId, secondCurrencyId, amount, marketPrice])

    useEffect(() => {
        if (pairList && pairList.length > 0 && firstCurrencyId != '') {

            let usrAsset = walletData && walletData.length > 0 && walletData.find((el) => el._id == firstCurrencyId)
            if (usrAsset) {
                setUsrBalance(usrAsset.spotBal)
            }

            let filter = pairList.filter((el) => el.firstCurrencyId == firstCurrencyId)
            if (filter) {
                // console.log(filter, '----filter')
                let data = filter && filter.length > 0 && filter.map((item) => {
                    return {
                        'coin': item.secondCurrencySymbol,
                        '_id': item.secondCurrencyId,
                    }
                })

                if (data) {
                    setSecondCoinList(data)
                    setFormValue({ ...formValue, ...{ 'secondCurrencyId': data[0]._id, 'secondCurrencySymbol': data[0].coin } })
                }
            }
        }
    }, [firstCurrencyId])

    useEffect(() => {
        if (pairList && pairList.length > 0) {
            let obj = pairList[0]
            let usrAsset = walletData && walletData.length > 0 && walletData.find((el) => el._id == obj.firstCurrencyId)

            if (usrAsset) {
                setUsrBalance(usrAsset.spotBal)
            }

            if (obj) {

                let newValue = {
                    'firstCurrencyId': obj.firstCurrencyId,
                    'secondCurrencyId': obj.secondCurrencyId,
                    'firstCurrencySymbol': obj.firstCurrencySymbol,
                    'secondCurrencySymbol': obj.secondCurrencySymbol,
                }
                setFormValue({ ...formValue, ...newValue })
            }

            let filter = pairList.filter((el) => el.firstCurrencyId == obj.firstCurrencyId)
            if (filter) {
                // console.log(filter, '----filter')
                let data = filter && filter.length > 0 && filter.map((item) => {
                    return {
                        'coin': item.secondCurrencySymbol,
                        '_id': item.secondCurrencyId,
                    }
                })

                if (data) {
                    setSecondCoinList(data)
                }
            }

        }
    }, [pairList, walletData])

    useEffect(() => {

        if(firstCoin){
            setFirstCoinList(firstCoin)
        }

        if(secondCoin){
            setSecondCoinList(secondCoin)
        }
        
    }, [firstCoin, secondCoin])

    // console.log(formValue, 'formValueformValueformValueformValue')
    // console.log(secondCoinList, '---secondCoinList')
    return (
        <div className="dashboard_box">
            <form className="contact_form instant_ex_form">
                <div className="settingsSelect">
                    {
                        marketPrice > 0 &&
                        <div>
                            <p className="cur_mark_price">
                                Current Market Rate:
                                <span>
                                    1 {firstCurrencySymbol} = {toFixed(marketPrice, 8)} {secondCurrencySymbol}
                                </span>
                            </p>
                        </div>
                    }

                    <GridContainer>
                        <GridItem sm={12} md={6} className="m-auto">
                            <div className="form-group">
                                <div className="d-flex align-items-center justify-content-between">
                                    <label>You Send</label>
                                    <label>Balance: <span>{toFixed(usrBalance, 8)} {firstCurrencySymbol}</span></label>
                                </div>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        name="amount"
                                        class="form-control"
                                        placeholder="0.00"
                                        onChange={handleChange}
                                        value={amount}
                                    />
                                    <div className="input-group-append">
                                        <Select name="firstCurrencyId" onChange={handleChange} value={firstCurrencyId}>
                                            {
                                                firstCoinList && firstCoinList.length > 0 && firstCoinList.map((item, key) => {
                                                    if (item.coin != 'UPRO')
                                                        return (
                                                            <MenuItem value={item._id}>{item.coin}</MenuItem>
                                                        )
                                                })
                                            }
                                            {/*<MenuItem value={'XRP'}>XRP</MenuItem>
                                            <MenuItem value={'DOGE'}>DOGE</MenuItem>
                                            <MenuItem value={'CTC'}>CTC</MenuItem>
                                            <MenuItem value={'BNP'}>BNP</MenuItem>
                                            <MenuItem value={'BUSD'}>BUSD</MenuItem>*/}
                                            {
                                                firstCoinList && firstCoinList.length > 0 && firstCoinList.map((item, key) => {
                                                    if (item.coin == 'UPRO')
                                                        return (
                                                            <MenuItem value={item._id}>{item.coin}</MenuItem>
                                                        )
                                                })
                                            }
                                            {/* <MenuItem value={1}>INR</MenuItem>
                                            <MenuItem value={2}>USD</MenuItem> */}
                                        </Select>
                                    </div>
                                </div>
                                {
                                    errors && errors.firstCurrencyId &&
                                    <span className="text-danger">{errors.firstCurrencyId}</span>
                                }
                                <br />
                                {
                                    errors && errors.amount &&
                                    <span className="text-danger">{errors.amount}</span>
                                }
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem sm={12} md={6} className="m-auto">
                            <div className="transfer-secito">
                                <a href='#' onClick={handleSwap} >
                                    <i class="fas fa-exchange-alt"></i>
                                </a>
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem sm={12} md={6} className="m-auto">
                            <div className="form-group">
                                <div className="d-flex align-items-center justify-content-between">
                                    <label>You Get</label>
                                    <label>Fee: <span>{fee}%</span></label>
                                </div>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        name="search"
                                        class="form-control"
                                        placeholder="0.00"
                                        value={receiveAmount}
                                        disabled
                                    />
                                    <div className="input-group-append">
                                        <Select name="secondCurrencyId" onChange={handleChange} value={secondCurrencyId}>
                                            {
                                                secondCoinList && secondCoinList.length > 0 && secondCoinList.map((item, key) => {
                                                    if (item.coin != 'UPRO')
                                                    return (
                                                        <MenuItem value={item._id}>{item.coin}</MenuItem>
                                                    )
                                                })
                                            }
                                           {/* <MenuItem value={'XRP'}>XRP</MenuItem>
                                            <MenuItem value={'DOGE'}>DOGE</MenuItem>
                                            <MenuItem value={'CTC'}>CTC</MenuItem>
                                            <MenuItem value={'BNP'}>BNP</MenuItem>
                                            <MenuItem value={'BUSD'}>BUSD</MenuItem>*/}
                                            {
                                                secondCoinList && secondCoinList.length > 0 && secondCoinList.map((item, key) => {
                                                    if (item.coin == 'UPRO')
                                                        return (
                                                            <MenuItem value={item._id}>{item.coin}</MenuItem>
                                                        )
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                {
                                    errors && errors.secondCurrencyId &&
                                    <span className="text-danger">{errors.secondCurrencyId}</span>
                                }
                            </div>
                        </GridItem>
                    </GridContainer>
                    <div className="text-center">
                        {
                            <button
                                type='button'
                                class="btn btn-primary text-uppercase py-2"
                                onClick={handleSubmit}
                                disabled={loader}
                            >
                                {loader && <i className="fas fa-spinner fa-spin"></i>}
                                Exchange Now
                            </button>
                        }
                    </div>
                </div>
            </form >
        </div >
    );
}

export default InstantOrder;