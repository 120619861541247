// import package
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CodeVerification = (props) => {
    const { t, i18n } = useTranslation();
    // props
    const { show, onHide, onSubmit, onChange, formValue } = props;

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('SECURITY_PIN')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="confirm_body">
                    {/* <img
                        src={require("../../assets/images/postconfirm.png")}
                        alt=""
                        className="img-fluid"
                    /> */}
                    <div className="model_detail_text justify-content-center">
                        <div className='contact_form'>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('ENTER_CODE')}
                                    name="code"
                                    value={formValue.code}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Subscribe new_confirm_pay_button mat_205">
                        <button className="btn btn-primary" onClick={onHide}>
                            {t('CANCEL')}
                        </button>
                        <button
                            className="ml-2 btn btn-primary"
                            onClick={onSubmit}
                        >
                            {t('RELEASE')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CodeVerification;