// import package
import React from 'react';

// import component
import ViewUpiDetail from './ViewUpiDetail';
import EditUpiDetail from '../UPIAccount/EditUpiDetail';
import ListUpiDetail from '../UPIAccount/ListUPIDetail';


const UpiAccount = () => {

    return (
        <div className="dashboard_box">

            <ListUpiDetail />

            <EditUpiDetail />

            <ViewUpiDetail />


        </div>
    )
}

export default UpiAccount;