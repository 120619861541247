import isEmpty from '../../lib/isEmpty';

const validation = (value, payByData) => {
    let errors = {};
    // console.log(value,'valuevalue')
    // if (isEmpty(value.price)) {
    //     errors.price = "REQUIRED"
    // } else if (isNaN(value.price)) {
    //     errors.price = "ONLY_NUMERIC"
    // }

    if (isEmpty(value.side)) {
        errors.side = "SIDE_REQUIRED"
    } else if (!['buy', 'sell'].includes(value.side)) {
        errors.value = "INVALID_SIDE"
    }

    if (isEmpty(value.firstCoinId)) {
        errors.firstCoin = "REQUIRED"
    }

    
    if(!isEmpty(value.firstCoin)){

        if (isEmpty(value.payBy)) {
            errors.payBy = "PAYMENT_TYPE_REQUIRED"
        } else if(value.side == "sell" && value.payBy == 'bank' && payByData.bankDetail.length == 0){
           errors.payBy = 'Please add a bank account'
        } else if(value.side == "sell" && value.payBy == 'gpay' && payByData.qrCodeDetail.length == 0){
           errors.payBy = 'Please add a gpay account'
        }else if(value.side == "sell" && value.payBy == 'upi' && payByData.upiDetail.length == 0){
           errors.payBy = 'Please add a UPI account'
        }

        if (isEmpty(value.secondCoinId)) {
            errors.secondCoin = "REQUIRED"
        }

        if (isEmpty(value.price)) {
            errors.price = "PRICE_REQUIRED"
        } else if (isNaN(value.price)) {
            errors.price = "ONLY_NUMERIC"
        } else if (parseFloat(value.price) <= 0) {
            errors.price = "ONLY_NUMERIC_POSITIVE"
        }

        if (isEmpty(value.quantity)) {
            errors.quantity = "QUANTITY_REQUIRED"
        } else if (isNaN(value.quantity)) {
            errors.quantity = "QUANTITY_ONLY_NUMERIC"
        } else if (parseFloat(value.quantity) <= 0) {
            errors.quantity = "ONLY_NUMERIC_POSITIVE"
        }

        if (isEmpty(value.minLimit)) {
            errors.minLimit = "MIN_LIMIT_REQUIRED"
        } else if (isNaN(value.minLimit)) {
            errors.minLimit = "ONLY_NUMERIC_POSITIVE"
        } else if (parseFloat(value.minLimit) <= 0) {
            errors.minLimit = "ONLY_NUMERIC_POSITIVE"
        } else if (parseFloat(value.maxLimit) < parseFloat(value.minLimit)) {
            errors.minLimit = "LESS_LIMIT_PURCHASE"
        }

        if (isEmpty(value.maxLimit)) {
            errors.maxLimit = "MAX_LIMIT_REQUIRED"
        } else if (isNaN(value.maxLimit)) {
            errors.maxLimit = "ONLY_NUMERIC_POSITIVE"
        } else if (parseFloat(value.maxLimit) <= 0) {
            errors.maxLimit = "ONLY_NUMERIC_POSITIVE"
        }
        
        if (value.endDate == null || value.endDate == '') {
            errors.endDate = "SELECT_DATE";
        } else if (new Date(value.endDate) < new Date()) {
            errors.endDate = "GREATER_THEN_CURRENT";
        }

        if (value.isTerms != true) {
            errors.isTerms = "ACCEPT_TERMS";
        }
    }
    


    return errors;
}

export default validation;