// import package
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const IdProofConfirm = (props) => {
    const { t, i18n } = useTranslation();
    // props
    const { show, onHide } = props;

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('KYC_REMINDER')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 style={{'color': 'black'}}>Please Update a Id Proof</h4>
                <div className="confirm_body">
                    {/* <img
                        src={require("../../assets/images/postconfirm.png")}
                        alt=""
                        className="img-fluid"
                    /> */}
                    {/*<span>Please Update a Address Proof</span>*/}
                    {/*<div className="model_detail_text justify-content-center">
                        <div className='contact_form'>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('ENTER_CODE')}
                                    name="code"
                                    value={'formValue.code'}
                                    // onChange={onChange}
                                />
                                <span>Please Update a Address Proof</span>
                            </div>
                        </div>
                    </div>*/}
                    <div className="Subscribe new_confirm_pay_button mat_205">
                        <button className="btn btn-primary" onClick={onHide}>
                            {t('CLOSE')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default IdProofConfirm;