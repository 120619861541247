import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// import component
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import FooterInner from "../components/Footer/Footer_innerpage"
import P2P from '../components/P2P';
import Footer from "../components/Footer/Footer"

// import lib
import { toastAlert } from '../lib/toastAlert';
import isEmpty from '../lib/isEmpty';

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const P2pPage = (props) => {
  
  const history = useHistory();
  const { ...rest } = props;

  const usrAccount = useSelector((state) => state.account);

  const fetchData = () => {
     if(!isEmpty(usrAccount)){
       
       if((!usrAccount.firstName && usrAccount.firstName != undefined)  || (usrAccount.lastName != undefined && !usrAccount.lastName)){
          toastAlert('error','Please update your profile details.','Profile')
          history.push('/profile')
       }
    }
  }

  useEffect(()=> {     
     fetchData()
  },[usrAccount])

  return (
    <div className="dashboard_container page_wrap page_wrap_dark">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo_light.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="profileContent userPages padin_p2p">
            <div className="container-fluid">
              <P2P
                type="list"
              />
            </div>
          </div>



          {/* <FooterInner /> */}
          <Footer type={'afterLogin'} />

        </div>
      </div>
    </div>
  );
}

export default P2pPage;