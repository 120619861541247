// import package
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import $ from "jquery";
// import component
import EmailForm from './EmailForm';
import MobileForm from './MobileForm';

const LoginForm = () => {
    const { t, i18n } = useTranslation();

    // state
    const [formType, setFormType] = useState('email')

    // function
    const handleFormType = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setFormType(value)
    }

    useEffect(() => {
        loadScript()
      }, []);

    function loadScript() {
        $(".radio_inp_email").click(function () {      
          $(".radio_inp_email").addClass('active');
          $(".radio_inp_mobile").removeClass('active');    
        });
        $(".radio_inp_mobile").click(function () {      
            $(".radio_inp_mobile").addClass('active');
            $(".radio_inp_email").removeClass('active');    
        }); 
      }

    return (
        <form className="login_form p-4 mb-4" data-aos="fade-up">
            <h3 className="login_title_8">{t('LOGIN')}</h3>
            <div className="flex_inpur_sect">
            <RadioGroup aria-label="formType" name="formType" value={formType} onChange={handleFormType}>
                    <FormControlLabel value="email" control={<Radio />} label="Email" className="radio_inp_email active" />
                    <FormControlLabel value="mobile" control={<Radio />} label="Mobile" className="radio_inp_mobile" />
                </RadioGroup>

            </div>

            {
                formType == 'email' && <EmailForm />
            }

            {
                formType == 'mobile' && <MobileForm />
            }

            <div className="login_link_bottom">
                <Link to="/recover-password" className="">
                    {t('FORGOT_PASSWORD')}?
                </Link>
                <Link to="/register" className="">
                    {/* {t("DON'T_HAVE_ACCOUNT")}? */}
                    {t('SIGN_IN')}
                </Link>                            
            </div>
            <div className="login_link_bottom mt-3">
                <Link to="/recover-pin" className="">
                    {t('FORGOT_PIN')}?
                </Link>
                <Link to="/email-resend-verification" className="">
                    {t('RESEND_VERIFICATION')}?
                </Link>
            </div>
        </form>
    )
}

export default LoginForm;