import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import DataTable from 'react-data-table-component';

// import actions
import { getOrderHistory } from '../../actions/instantAction';

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';
import { toFixed } from '../../lib/roundOf';


const columns = [
    {
        name: 'Date',
        selector: row => row.date,
        // width: '100px', 
    },
    {
        name: 'Tnx Id',
        selector: row => row.filterId,
        width: '100px', 
    },
    {
        name: 'From',
        selector: row => row.firstCurrencySymbol,
        width: '100px', 
    },
    {
        name: 'To',
        selector: row => row.secondCurrencySymbol,
        width: '100px', 
    },
    {
        name: 'Pay Amount',
        selector: row => row.fromAmount,
    },
    {
        name: 'Receive Amount',
        selector: row => row.toAmount,
    },
    {
        name: 'Fee',
        selector: row => row.fee,
    },
    {
        name: 'Status',
        selector: row => row.status,
        width: '100px', 
    },
];

const InstantHistory = forwardRef((props, ref) => {

    // state
    const [loader, setLoader] = useState(false)
    const [record, setRecord] = useState({
        'data': [],
        'count': 0
    })
    const [filter, setFilter] = useState({
        'page': 1,
        'limit': 10,
        'search': ''
    })

    const { search } = filter;

    const handlePageChange = page => {
        let filterData = { ...filter, ...{ 'page': page } }
        setFilter(filterData)
        getHistory(filterData)
    };

    const handleChange = (e) => {
        let { name, value } = e.target;
        let filterData = { ...filter, ...{ [name]: value } }
        console.log(filterData,'filterDatafilterData')
        setFilter(filterData)
        getHistory(filterData)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        let filterData = { ...filter, ...{ 'page': page, 'limit': newPerPage } }
        setFilter(filterData)
        getHistory(filterData)
    };

    const getHistory = async (reqQuery) => {
        setLoader(true)
        let { status, result } = await getOrderHistory(reqQuery);
        setLoader(false)
        if (status == 'success') {
            console.log(result, 'resultresultresult')

            if (result && result.data && result.data.length > 0) {
                let resultArr = []
                result.data.map((item) => {
                    resultArr.push({
                        'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                        'firstCurrencySymbol': item.firstCurrencySymbol,
                        'secondCurrencySymbol': item.secondCurrencySymbol,
                        'fromAmount': toFixed(item.fromAmount, 6),
                        'toAmount': toFixed(item.toAmount, 6),
                        'address': item.toAddress,
                        'filterId': item.filterId,
                        'fee': toFixed(item.fee, 6) +' '+ item.secondCurrencySymbol,
                        'status': <div className="textStatusGreen">{item.status}</div>
                    })
                })
                setRecord({
                    'data': resultArr,
                    count: result.count
                })
            } else {
                setRecord({
                    'data': result.data,
                    'count': result.count
                })
            }
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            listData() {
                getHistory(filter)
            }
        })
    )

    useEffect(() => {
        getHistory(filter)
    }, [])

    return (
        <Fragment>
            <h3 className="dash_title">Exchange History</h3>
            <div className="dashboard_box">
                <div className="newUsersFilter contact_form settingsSelect mb-0 historyPageFilter">
                    <div className="newsSelectGroup">
                        <div className="tableSearchBox">
                            <div class="input-group">
                                <input
                                    type="text"
                                    name="search"
                                    value={search}
                                    onChange={handleChange}
                                    class="form-control"
                                    placeholder="Search by TnxId, From, To"
                                />
                                <div class="input-group-append">
                                    <span class="btnType1"><i class="fas fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={record.data}
                    paginationTotalRows={record.count}
                    noHeader
                    progressPending={loader}
                    pagination
                    paginationServer
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            </div>
        </Fragment>
    );
})

export default InstantHistory;