// import package
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import InfiniteScroll from "react-infinite-scroll-component";

// import lib
import { momentFormat } from '../../lib/dateTimeHelper';
import { toFixed } from '../../lib/roundOf';

const RecentPost = forwardRef((props, ref) => {

    // state
    const [list, setList] = useState([]);
    const { t, i18n } = useTranslation();
    const [hasMore, setHasMore] = useState(false)

    // redux
    const { isAuth } = useSelector(state => state.auth)

    useImperativeHandle(
        ref,
        () => ({
            postRecord(record) {
                setList(record)
            }
        }),
    )

    return (
        <div className="recent_post">
            <h4>{t('RECENT_POST')}</h4>
            <InfiniteScroll
                dataLength={list.length}
                next={() => setHasMore(false)}
                hasMore={hasMore}
                loader={<h4>{t('LOADING')}</h4>}
                height={650}
            >
            {
                list && list.length > 0 && list.map((item, key) => {
                    console.log(item,'itemitemitem')
                    return (
                        <div key={key}>
                            <div className="recent_post mr-2">
                                <div className="flex_details">
                                    <span className="text_color_w font_600">{t('POST_ID')}</span>
                                    <span className="clor_black text-right">#{item.postId}</span>
                                </div>
                                <div className="flex_details">
                                    <span className="text_color_w font_600">{t('POSTED_ON')}</span>
                                    <span className="text-right clor_black">{momentFormat(item.orderDate, 'DD MMM YYYY HH:mm')}</span>
                                </div>
                                <div className="flex_details">
                                    <span className="text_color_w font_600">{t('POST_END_DATE')}</span>
                                    <span className="text-right clor_black">{momentFormat(item.endDate, 'DD MMM YYYY HH:mm')}</span>
                                </div>
                                <div className="flex_details">
                                    <span className="text_color_w font_600">{t('TYPE')}</span>
                                    <span className="text-right clor_black">{item.side}<br />
                                        {/* {item.price} {item.secondCoin}<br />
                                        {item.quantity} {item.firstCoin}<br />
                                        {item.payBy} */}
                                    </span>
                                </div>
                                <div className="flex_details">
                                    <span className="text_color_w font_600">Per Price</span>
                                    <span className="text-right clor_black">{toFixed(item.price, 8)} {item.secondCoin}</span>
                                </div>
                                <div className="flex_details">
                                    <span className="text_color_w font_600">Available Qty.</span>
                                    <span className="text-right clor_black">{toFixed(item.quantity - item.filledQuantity, 8)} {item.firstCoin}</span>
                                </div>
                                <div className="flex_details">
                                    <span className="text_color_w font_600">Limit</span>
                                    <span className="text-right clor_black">Min: {toFixed(item.minLimit, 8)} {item.secondCoin} - Max: {toFixed(item.maxLimit, 8)} {item.secondCoin}</span>
                                </div>
                                <div className="flex_details">
                                    <span className="text_color_w font_600">Preferred Transfer</span>
                                    <span className="text-right clor_black">{item.payBy}</span>
                                </div>

                                {/* {
                                    isAuth && <div className="flex_details">
                                        <span className="text_color_w"> Status</span>
                                        <span className="text-right text_color_blue"><a href="">Edit My Post</a></span>
                                    </div>
                                } */}

                                <hr />
                            </div>
                        </div>
                    )
                })
            }
            </InfiniteScroll>
        </div>
    )
})

export default RecentPost;