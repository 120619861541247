// import package
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import clsx from 'classnames';
import { useTranslation } from 'react-i18next';
import { CountryDropdown } from 'react-country-region-selector';
import { useSelector, useDispatch } from 'react-redux';

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import action
import { updateQRDetail } from '../../actions/users';

// import lib
import validation from './validation';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
    'key': 0,
    'frontImage': '',
    'isPrimary': '',
   // 'formDisable':false
}

const EditQRDetail = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    // redux-state
    const { formDisable, formType, editRecord } = useSelector(state => state.qrCodeDetail);
   
    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();

    const { key, frontImage} = formValue;

    // function
   
    const handleFile = async (e) => {
        const { name, files } = e.target;
        let formData = { ...formValue, ...{ [name]: files[0] } }
        setFormValue(formData)
        if (!isEmpty(validateError)) {
            setValidateError({})
        }
    }
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        let id=editRecord && editRecord._id;
        let primary=editRecord && editRecord.isPrimary

        let reqData = {
            id:editRecord && editRecord._id,
           qrImage:frontImage,
           isPrimary:editRecord && editRecord.isPrimary
            
      }
       // alert(reqData)
      
        let validationError = validation(reqData)
     
        if (!isEmpty(validationError)) {
          console.log("validation---",validationError)
            setValidateError(validationError)
            setLoader(false)
            return
        }

        try {
            
            const formData = new FormData();
            formData.append('qrImage', frontImage);
            formData.append('id', id);
            formData.append('isPrimary', primary);

            let { status, loading, error, message } =  await updateQRDetail(formData, dispatch);
            setLoader(loading)
            if (status == 'success') {
                toastAlert('success', t(message), 'upiForm')
                setFormValue(initialFormValue);
            } else {
                if (error) {
                    setValidateError(error);
                }
                toastAlert('success', t(message), 'upiForm')
            }
        } catch (err) { }
    }

    useEffect(() => {
        if (formType == 'edit') {
            let formData = {
                'key': editRecord.key,
                'frontImage': editRecord.frontImage,
                'isPrimary': editRecord.isPrimary
            }
            setFormValue(formData)
        } else if (formType == 'add') {
            setFormValue(initialFormValue)
        }
        
    }, [editRecord])
console.log("formDisableqr===",formDisable)
    return (
        <form className={clsx("contact_form boxSpace", { "disabledForm": formDisable }, "pt-4")}>
            {/* <h5 className="dash_subtitle pb-3 mb-3">Bank {formType == 'edit' && key}</h5> */}
            <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                <div className="form-group">
                        <label>{t("QR_NAME")}</label>
                        <div className="custom-file">
                            <input
                                type="file"
                                className="custom-file-input"
                                aria-describedby="inputGroupFileAddon01"
                                name="frontImage"
                                onChange={handleFile}
                            />
                            <label className="custom-file-label">
                                {t("MAX_1MB_IMG")}
                            </label>
                        </div>
                        {frontImage&& frontImage.name

                        }
                        {
                            validateError.frontImage && <p className="error-message">{t(validateError.frontImage)}</p>
                        }
                    </div>
                        {/* <div className="form-group">
                            <label>{t("QR_NAME")}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="upiId"
                                value={upiId}
                                onChange={handleChange}
                            />
                            {
                                validateError.upiId && <p className="error-message">{t(validateError.upiId)}</p>
                            }
                        </div> */}
                        <div className="form-group">
                            <button
                                type="button"
                                onClick={handleFormSubmit}
                                className="btn btn-primary text-uppercase py-2"
                            >
                                {loader && <i class="fas fa-spinner fa-spin"></i>}
                                {t("SUBMIT")}
                            </button>
                        </div>
                  
                </GridItem>
                {/* <GridItem xs={12} sm={6} md={6} lg={3}>
                    <div className="form-group">
                        <label>{t("ACCOUNT_NUMBER")}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="accountNo"
                            value={accountNo}
                            onChange={handleChange}
                        />
                        {
                            validateError.accountNo && <p className="error-message">{t(validateError.accountNo)}</p>
                        }
                    </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <div className="form-group">
                        <label>{t("ACCOUNT_HOLDER_NAME")}</label>
                        <input type="text" className="form-control"
                            name="holderName"
                            value={holderName}
                            onChange={handleChange}
                        />
                        {
                            validateError.holderName && <p className="error-message">{t(validateError.holderName)}</p>
                        }
                    </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <div className="form-group">
                        <label>{t("IBAN_CODE")}</label>
                        <input type="text" className="form-control"
                            name="bankcode"
                            value={bankcode}
                            onChange={handleChange}
                        />
                        {
                            validateError.bankcode && <p className="error-message">{t(validateError.bankcode)}</p>
                        }
                    </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <div className="form-group">
                        <label>{t("BANK")}{" "}{t("COUNTRY")}</label>
                        <CountryDropdown
                            value={country}
                            onChange={handleCountry}
                            className="country_dropdown form-control"
                        />
                        {
                            validateError.country && <p className="error-message">{t(validateError.country)}</p>
                        }
                    </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <div className="form-group">
                        <label>{t("BANK")}{" "}{t("CITY")}</label>
                        <input type="text" className="form-control"
                            name="city"
                            value={city}
                            onChange={handleChange}
                        />
                        {
                            validateError.city && <p className="error-message">{t(validateError.city)}</p>
                        }
                    </div>
                </GridItem> */}
                {/* <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div className="form-group">
                        <button
                            type="button"
                            onClick={handleFormSubmit}
                            className="btn btn-primary text-uppercase py-2"
                        >
                            {loader && <i class="fas fa-spinner fa-spin"></i>}
                            {t("SUBMIT")}
                        </button>
                    </div>
                </GridItem> */}
            </GridContainer>
        </form>

    )
}

export default EditQRDetail;