import React, { useEffect, useState } from "react";

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// import action
import { getCMSPage } from '../actions/commonAction';

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const AboutUsPage = (props) => {
  // state
  const [content, setContent] = useState('');

  // function
  const fetchCmsPage = async () => {
    try {
      const { status, loading, result } = await getCMSPage('about_us');
      if (status == 'success') {
        setContent(result.content)
        document.title = result.title;
      }
    } catch (err) { }
  }

  useEffect(() => {
    fetchCmsPage()
  }, [])

  return (
    <div className="dashboard_container page_wrap page_wrap_dark">            
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
      <Header className="header"
        color="transparent"
        // routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_light.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
      />
      <div className="profileContent userPages padin_p2p terms_page">
            <div className="container">
              <GridContainer className="justify-content-center">
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="p2p_card min-h-auto terms_page_card">
                    <div dangerouslySetInnerHTML={{ '__html': content }} />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
      <Footer  type={'afterLogin'}/>
    </div>
    </div>
    </div>
  );
}

export default AboutUsPage;