// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {},
        passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/g;
   
    if (isEmpty(value.pinNumber)) {
        errors.pinNumber = "REQUIRED";
    } else if (isNaN(value.pinNumber)) {
        errors.pinNumber = "INVALID_CODE";
    } else if (value.pinNumber.length < 6) {
        errors.pinNumber = "INVALID_CODE";
    }
    
    if (isEmpty(value.confirmPinNumber)) {
        errors.confirmPinNumber = "REQUIRED";
    } else if (value.pinNumber != value.confirmPinNumber) {
        errors.confirmPinNumber = "NOT_MATCH";
    }


    return errors;
}

export default validation;