// import constant
import {
    SET_INSTANT_PAIRS,
} from '../constant';

const initialValue = [{
    pairList : [],
    firstCoinList : [],
    secondCoinList : []
}]

const instantPair = (state = initialValue, action) => {
    switch (action.type) {
        
        case SET_INSTANT_PAIRS:
            const { data } = action;
            return {
                pairList : data.data,
                firstCoinList : data.firstCoin,
                secondCoinList : data.secondCoin
            };
        default:
            return state;
    }
}

export default instantPair;