import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import clsx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
// import context
import SocketContext from '../Context/SocketContext';

// import action
import { getPairList, setPairList, setTradePair, setMarketPrice } from '../../actions/spotTradeAction';
import {
  getAssetByCurrency,
  setUserFirstCurrency,
  setUserSecondCurrency
} from '../../actions/walletAction';

// import lib
import isEmpty from '../../lib/isEmpty';
import { toFixed } from '../../lib/roundOf';
import isLogin from '../../lib/isLogin';
import { capitalize } from '../../lib/stringCase';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto() {


  const history = useHistory();
  const dispatch = useDispatch();
  const { tikerRoot } = useParams();
  const socketContext = useContext(SocketContext);
  const { t, i18n } = useTranslation();
  // state
  const [pairLists, setPairLists] = useState([]);
  const [secondCurrencyList, setsecondCurrencyList] = useState([]);
  const [search, setsearchValue] = useState("");

  // redux-state
  const tickerData = useSelector(state => state.marketPrice);
  const pairData = useSelector(state => state.tradePair);
  const pairListData = useSelector(state => state.pairList);
  const currencyDoc = useSelector(state => state.currency);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // function
  const fetchAssetByCurrency = async (spotPairId, type) => {
    try {
      if (!isLogin()) {
        return true
      }
      const { status, loading, error, result } = await getAssetByCurrency(spotPairId);
      if (status == 'success') {
        if (type == 'firstCurrency') {
          setUserFirstCurrency(result, dispatch)
        } else if (type == 'secondCurrency') {
          setUserSecondCurrency(result, dispatch)
        }
      } else { }
      return true
    } catch (err) { }
  }

  const fetchPairList = async () => {
    try {
      const { status, loading, result } = await getPairList();
      if (status == 'success') {
        setPairList(result, dispatch)
        if (result && result.length > 0) {
          if (isEmpty(tikerRoot)) {
            let pair = `${result[0].firstCurrencySymbol}_${result[0].secondCurrencySymbol}`
            history.replace('/spot/' + pair)
            // history.push('/spot/' + pair)
            await fetchAssetByCurrency(result[0].firstCurrencyId, 'firstCurrency')
            await fetchAssetByCurrency(result[0].secondCurrencyId, 'secondCurrency')
            await setMarketPrice(result[0], dispatch)
            await setTradePair(result[0], dispatch)
            // getMarketPrice(result[0]._id, dispatch);
          } else {
            let currency = tikerRoot.split('_');
            let pairDetail = result.find((el => el.firstCurrencySymbol == currency[0] && el.secondCurrencySymbol == currency[1]))
            await fetchAssetByCurrency(pairDetail.firstCurrencyId, 'firstCurrency')
            await fetchAssetByCurrency(pairDetail.secondCurrencyId, 'secondCurrency')
            // getMarketPrice(pairDetail._id, dispatch);
            await setMarketPrice(pairDetail, dispatch)
            await setTradePair(pairDetail, dispatch)
          }
        }
      } else { }
    }
    catch (err) { }
  }

  const handlePairChange = async (pairData) => {
    let pair = `${pairData.firstCurrencySymbol}_${pairData.secondCurrencySymbol}`
    history.push('/spot/' + pair)
    if (tikerRoot != pair) {
      await fetchAssetByCurrency(pairData.firstCurrencyId, 'firstCurrency')
      await fetchAssetByCurrency(pairData.secondCurrencyId, 'secondCurrency')
      await setMarketPrice(pairData, dispatch)
      await setTradePair(pairData, dispatch)
      socketContext.socket.off("marketPrice");
      socketContext.socket.off("orderBook");
      socketContext.socket.off("recentTrade");
    }
  }

  const searchpair = (e) => {
    setsearchValue(e.target.value)
    var searcharr = [];
    if (pairListData && pairListData.length > 0) {
      for (var i = 0; i < pairListData.length; i++) {
        var temppair =
          pairListData[i].firstCurrencySymbol +
          "/" +
          pairListData[i].secondCurrencySymbol;
        if (temppair.indexOf(e.target.value.toUpperCase()) !== -1) {
          searcharr.push(pairListData[i]);
        }
        if (i == pairListData.length - 1) {
          setPairLists(searcharr)
        }
      }
    }

  };

  const secondCurrencyArray = async (pairListData) => {
    let uniqueChars = [];
    pairListData.forEach((c) => {
      if (!uniqueChars.includes(c.secondCurrencySymbol)) {
        uniqueChars.push(c.secondCurrencySymbol);
      }
    });

    let positionINR = await arrayChangePosition(uniqueChars, 0, 'INR')
    let positionTRX = await arrayChangePosition(positionINR, 1, 'TRX')
    // console.log(data,'datadatadata')
    setsecondCurrencyList(positionTRX);
  }

  const currencySymbol = (symbol) => {
     try {
       
       let findCurDoc = currencyDoc.find((el) => el.coin == symbol)

       if(findCurDoc){
           return (findCurDoc && findCurDoc.type == 'token' && !['prc20'].includes(findCurDoc.tokenType)) ? `${findCurDoc.coin.replace('.ERC20','')} (${capitalize(findCurDoc.tokenType)})` : findCurDoc.coin.replace('.ERC20','');
       }

       return symbol;
     } catch (err) {
        return symbol;
     }
  }

  useEffect(() => {
    if (pairListData && pairListData.length > 0) {
      secondCurrencyArray(pairListData);
      
      let Arr = [], geccArr = [],  pavoArr = [], relanasArr = [], othersArr = [];
      pairListData && pairListData.length > 0 && pairListData.map((item) => {
          let pair = item.firstCurrencySymbol + item.secondCurrencySymbol;
          
          if(item.firstCurrencySymbol == 'GECC'){
             geccArr.push(item)
          }else if(item.firstCurrencySymbol == 'RELANAS'){
            relanasArr.push(item)
          }else if(item.firstCurrencySymbol == 'PAVO'){
            pavoArr.push(item)
          }else {
            othersArr.push(item)
          }

      })

      Arr = [...geccArr,...relanasArr,...pavoArr,...othersArr];
      setPairLists(Arr)
      // console.log(Arr,'ArrArrArrArr')
      // setPairLists(el => {
      //     let findData = pairListData.find((el) => el.firstCurrencySymbol == 'GECC' && el.secondCurrencySymbol == 'INR')
      //     let findData1 = pairListData.find((el) => el.firstCurrencySymbol == 'PAVO' && el.secondCurrencySymbol == 'INR')
      //     let data = arrayChangePosition(pairListData, 0, findData)
      //     let data1 = arrayChangePosition(pairListData, 1, findData1)

      //     return data1;
      // })
    }
  }, [pairListData])
  useEffect(() => {
    if (!isEmpty(pairData)) {
      // socket
      socketContext.socket.on('marketPrice', (result) => {
        if (result.pairId == pairData.pairId) {
          setMarketPrice(result.data, dispatch)
          let tempPairList = pairLists;
          let pairIndex = tempPairList.findIndex((el => el._id == result.pairId))
          if (pairIndex >= 0) {
            tempPairList[pairIndex] = {
              ...tempPairList[pairIndex],
              ...{
                'markPrice': result.data.markPrice,
                'change': result.data.change
              }
            }
            setPairLists(tempPairList)
          }
        }
      })
    }
  }, [pairData])

  const arrayChangePosition = (array, toIndex, value) => {
    
    if(!(array.includes(value))){
      return array;
    }

    const fromIndex = array.indexOf(value);
    const element = array.splice(fromIndex, 1)[0];
    array.splice(toIndex, 0, element)
    return array
  }

  useEffect(() => {
    fetchPairList();
    return () => {
      socketContext.socket.off("marketPrice");
      socketContext.socket.off("orderBook");
      socketContext.socket.off("recentTrade");
    }
  }, [])

  return (
    <div className="market_table">
      <div className="trade_search_table">
        <input type="text" placeholder="search" onChange={searchpair} />
        <i class="fas fa-search"></i>
      </div>
      <div className={classes.root + " slaide_tab"}>
        {/*<AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable  tabs example tab_scroll_section"
          >
            {
              secondCurrencyList && secondCurrencyList.length > 0 && secondCurrencyList.map((item, key) => {
                return (
                  <Tab label={item} {...a11yProps(key)} className="tab_button" />
                )
              })
            }
          </Tabs>
        </AppBar>*/}
        {/* {
          secondCurrencyList && secondCurrencyList.length > 0 && secondCurrencyList.map((el, index) => { 
            return ( */}
              <TabPanel /*value={value} index={index}*/ className="p-padding_section">
                <Scrollbars style={{ width: "100%", height: 420 }}>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      {/* <th scope="col"><i class="far fa-star"></i></th> */}
                      <th scope="col">{t('PAIRS')}</th>
                      <th scope="col">{t('LAST_PRICE')}</th>
                      <th scope="col">{t('24H_CHANGE')}</th>
                    </tr>
                  </thead>
                  
                  <tbody>
                    {
                      pairLists && pairLists.length > 0 && pairLists.map((item, key) => {
                        // if (el == item.secondCurrencySymbol) {
                          return (

                            <tr key={key} onClick={() => handlePairChange(item)}>
                              {/* <td><i class="far fa-star"></i></td> */}
                              <td className={(tikerRoot == item.firstCurrencySymbol + "_"+ item.secondCurrencySymbol) ? "clicked" : "" }>{currencySymbol(item.firstCurrencySymbol)}/{currencySymbol(item.secondCurrencySymbol)}</td>
                              <td>{toFixed(item.markPrice, item.secondFloatDigit)}</td>
                              <td className={clsx({
                                "span_menu_gerrn": item.change > 0,
                                "span_menu_red": item.change <= 0
                              })}>{toFixed(item.change, 2)}%</td>
                            </tr>
                          )
                        // }
                      })
                    }

                  </tbody>                 
                </table>
                </Scrollbars>
              </TabPanel>
           {/* )
          })
        } */}
      </div>
    </div>
  )
}
