let key = {};

if (process.env.REACT_APP_MODE === "production") {
    console.log("Set Production Config")
    const API_URL = '';

    key = {}

}else if (process.env.REACT_APP_MODE === "demo") {
    console.log("Set Demo Config")
    const API_URL = 'https://c1api.wearedev.team';

    key = {
        SITE_NAME : 'EXHANGE',
        secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
        CRYPTO_SECRET_KEY: "1234567812345678",
        RECAPTCHA_SITE_KEY: "6LeME0AgAAAAAEpVGb2Af5jQA7Sry9jVHsm3U3yf",
        MOBILE_RECAPTCHA_SITE_KEY: "6Lc3b4ghAAAAAKyKtbcjUmmm64OeB-U_oYQ4i3As", 
        API_URL: API_URL,
        FRONT_URL: 'https://c1exchange-frontend.pages.dev',
        ADMIN_URL: 'https://c1exchange-adminpanel.pages.dev/admin',
        SOCKET_URL: API_URL,
        getGeoInfo: "https://ipapi.co/json/",
        AUTHENTICATOR_URL: {
            PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
            APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        },
        IMAGE : {
          BANK_PROOF_URL_PATH : '/images/bank/',
        },
        // ZAAKPAY
        // zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",
        // MerchantID: "8a39b3d6b6644b03991f3d8da27e0df5",
        // returnUrl: `${API_URL}/api/zaakpay/zaakpayconfirmed`,
        // zaaksecretkey: "5f236b2ba55f4b22b309e39eb21c45d7"

        zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",
        MerchantID: "",
        returnUrl: `${API_URL}/api/zaakpay/zaakpayconfirmed`,
        zaaksecretkey: ""
    };

}else if (process.env.REACT_APP_MODE === "staging") {
    console.log("Set staging Config")
    const API_URL = '';

    key = {}

} 
else {
    console.log("Set Development Config")
    const API_URL ='http://localhost'
    
    key = {
        SITE_NAME : 'EXHANGE',
        secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
        CRYPTO_SECRET_KEY: "1234567812345678",
        RECAPTCHA_SITE_KEY: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
        MOBILE_RECAPTCHA_SITE_KEY: "6Lc3b4ghAAAAAKyKtbcjUmmm64OeB-U_oYQ4i3As",
        API_URL: `${API_URL}:2053`,
        FRONT_URL: 'http://localhost', //'http://localhost:3000',
        ADMIN_URL: 'http://localhost:3001/admin',
        SOCKET_URL: `${API_URL}:2053`,
        getGeoInfo: "https://ipapi.co/json/",
        AUTHENTICATOR_URL: {
            PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
            APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        },
        IMAGE : {
          BANK_PROOF_URL_PATH : '/images/bank/',
        },

        // ZAAKPAY
        // zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",
        // MerchantID: "8a39b3d6b6644b03991f3d8da27e0df5",
        // returnUrl: `${API_URL}:2053/api/zaakpay/zaakpayconfirmed`,
        // zaaksecretkey: "5f236b2ba55f4b22b309e39eb21c45d7"

        zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",
        MerchantID: "6ed03015c3ab494b9729a4b7a268ac7b",
        returnUrl: `${API_URL}:2053/api/zaakpay/zaakpayconfirmed`,
        zaaksecretkey: "d0bcd095a6b647f182583a570c33a9e8"
    };
}


export default {
    ...key,
    ...{ SITE_DETAIL: require('./siteConfig').default }
};