import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// import component
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Announcement from 'components/Announcement';
import InstantOrder from 'components/InstantExchange/InstantOrder';
import InstantHistory from 'components/InstantExchange/InstantHistory';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const instant_ex_slider = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  margin: 30,
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};

const InstantExchange = (props) => {
  // props
  const { ...rest } = props;

  // hooks
  const listRef = useRef();

  // redux-state
  const priceCNV = useSelector((el) => el.priceConversion);
  // const { pairList } = useSelector((el) => el.instantPair);

  return (
    <div className="dashboard_container page_wrap page_wrap_dark">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo_light.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />

          <div className="dashboardContent userPages">
            <div className="container">
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <h3 className="dash_title">Instant Swap</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={7} lg={7}>
                  <Announcement />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="coin_data_list">
                    <div className="coin_list my-4">
                      <Slider {...instant_ex_slider} className="banner_videoslider instant_ex_slider">
                        {
                          priceCNV && priceCNV.length > 0 && priceCNV.map((item, key) => {
                            if((item.baseSymbol == 'TRX' && item.convertSymbol == 'INR')){
                              return (
                                <div>
                                  <p>{item.baseSymbol} / {item.convertSymbol}</p>
                                  <h4><b>{item.convertPrice}</b></h4>
                                </div>
                              )
                            }
                          })
                        }

                        {/*{
                          pairList && pairList.length > 0 && pairList.map((item, key) => {
                            if(!(item.firstCurrencySymbol == 'UPRO' && item.secondCurrencySymbol == 'INR')){
                              return (
                                <div>
                                  <p>{item.firstCurrencySymbol} / {item.secondCurrencySymbol}</p>
                                  <h4><b>{item.marketPrice}</b></h4>
                                </div>
                              )
                            }
                          })
                        }*/}
                        {/* <div>
                          <p>BTC / INR</p>
                          <h4>13,89,100.03</h4>
                        </div>
                        <div>
                          <p>USDT / INR</p>
                          <h4>82.71</h4>
                        </div>
                        <div>
                          <p>TRX / INR</p>
                          <h4>4.18</h4>
                        </div>
                        <div>
                          <p>PAVO / INR</p>
                          <h4>2.71</h4>
                        </div>
                        <div>
                          <p>GECC / INR</p>
                          <h4>12.53</h4>
                        </div>
                        <div>
                          <p>RELANAS / INR</p>
                          <h4>0.71</h4>
                        </div> */}
                      </Slider>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {/* CHILD COMPONENETS */}
                  <InstantOrder listRef={listRef} />
                  <InstantHistory ref={listRef} />
                  {/* CHILD COMPONENETS */}
                </GridItem>
              </GridContainer>
            </div>

          </div>
        </div>
      </div>
      <Footer type={'afterLogin'} />
    </div>
  );
}

export default InstantExchange;