// import package
import React, { Fragment, useState, useEffect } from "react";
import { MenuItem, Select, Button } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import clsx from 'classnames'
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';
// import action
import { allTradeOrder, allTradeOrderDoc } from '../../../actions/spotTradeAction'

// import lib
import { dateTimeFormat } from '../../../lib/dateTimeHelper'
import { capitalize } from '../../../lib/stringCase';

const columns = [
    {
        name: 'Date',
        selector: 'Date',
        sortable: false,
    },
    {
        name: 'Pair',
        selector: 'Pair',
        sortable: false,
    },
    {
        name: 'Side',
        selector: 'Side',
        sortable: false,
    },
    {
        name: 'Filled',
        selector: 'Filled',
        sortable: false,
    },
    {
        name: 'Price',
        selector: 'Price',
        sortable: false,
    },
    {
        name: 'Total',
        selector: 'Total',
        sortable: false,
    },
    {
        name: 'Fees',
        selector: 'Fees',
        sortable: false,
    }
];

const initialFormValue = {
    count: 0,
    data: [],
}

const initialFilter = {
    page: 1,
    limit: 10,
    pairName: 'all',
    orderType: 'all',
    search : ''
}

const TradeOrder = (props) => {
    // props
    const { filter: { pairList, orderTypes } } = props
    const { t, i18n } = useTranslation();
    // state
    const [orderData, setOrderData] = useState(initialFormValue)
    const [filter, setFilter] = useState(initialFilter)
    const [loader, setLoader] = useState(false);

    const { count, data } = orderData
    const { page, limit, pairName, orderType, search } = filter

    // function
    const handlePageChange = page => {
        let filterData = { ...filter, page: page }
        setFilter(filterData)
        fetchHistory(filterData)
    };

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let filterData = { ...filter, [name]: value }
        setFilter(filterData)
        fetchHistory(filterData)
    }

    const fetchHistory = async (reqQuery) => {
        try {
            setLoader(true)
            const { status, loading, result } = await allTradeOrder(reqQuery)
            setLoader(loading)
            if (status == 'success') {
                if (result && result.data && result.data.length > 0) {
                    let resultArr = []
                    result.data.map((item) => {
                        let pairData = pairList.find((el) => ((el.firstCurrencySymbol == item.firstCurrency) && (el.secondCurrencySymbol == item.secondCurrency)))
                        if(pairData){
                            let floatDigit = (item.buyorsell === 'buy') ? pairData.firstFloatDigit : pairData.secondFloatDigit;
                            resultArr.push({
                                'Date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                                'Pair': `${item.firstCurrency}/${item.secondCurrency}`,
                                'Side': <div className="apy_section"><span className={clsx({ "color_red": item.buyorsell == 'sell' }, { "color_green": item.buyorsell == 'buy' })}>{capitalize(item.buyorsell)}</span></div>,
                                'Filled': item.filledQuantity == 0 ? '-' : Number(item.filledQuantity).toFixed(pairData.firstFloatDigit),
                                'Price': Number(item.price).toFixed(pairData.secondFloatDigit),
                                'Total': Number(item.orderValue).toFixed(pairData.secondFloatDigit),
                                'Fees': Number(item.Fees).toFixed(floatDigit) + " " + FeeeSymbol(item.buyorsell,item.firstCurrency,item.secondCurrency)
                            })
                        }else{
                            resultArr.push({
                                'Date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                                'Pair': `${item.firstCurrency}/${item.secondCurrency}`,
                                'Side': <div className="apy_section"><span className={clsx({ "color_red": item.buyorsell == 'sell' }, { "color_green": item.buyorsell == 'buy' })}>{capitalize(item.buyorsell)}</span></div>,
                                'Filled': item.filledQuantity == 0 ? '-' : Number(item.filledQuantity).toFixed(8),
                                'Price': Number(item.price).toFixed(8),
                                'Total': Number(item.orderValue).toFixed(8),
                                'Fees': Number(item.Fees).toFixed(8) + " " + FeeeSymbol(item.buyorsell,item.firstCurrency,item.secondCurrency)
                            })
                        }
                    })
                    setOrderData({
                        'data': resultArr,
                        'count': result.count
                    })
                } else {
                    setOrderData({
                        'data': [],
                        'count': 0
                    })
                }
            }
        } catch (err) { }
    }

    const FeeeSymbol = (buyorsell,firstCurrency,secondCurrency) => {
       return buyorsell == 'buy' ? firstCurrency : secondCurrency;
    }

    const handlePDF = async (e) => {
        e.preventDefault();
        try {
            let reqQuery = filter;
            const { status, loading, result } = await allTradeOrderDoc(reqQuery)
            if (status == 'success') {
                if (result && result.data && result.data.length > 0) {
                    let resultArr = []
                    result.data.map((item) => {
                        let pairData = pairList.find((el) => ((el.firstCurrencySymbol == item.firstCurrency) && (el.secondCurrencySymbol == item.secondCurrency)))
                        if(pairData){
                            let floatDigit = (item.buyorsell === 'buy') ? pairData.firstFloatDigit : pairData.secondFloatDigit;
                            resultArr.push([
                                dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                                `${item.firstCurrency}/${item.secondCurrency}`,
                                capitalize(item.buyorsell),
                                item.filledQuantity == 0 ? '-' : Number(item.filledQuantity).toFixed(pairData.firstFloatDigit),
                                Number(item.price).toFixed(pairData.secondFloatDigit),
                                Number(item.orderValue).toFixed(pairData.secondFloatDigit),
                                Number(item.Fees).toFixed(floatDigit) + " " + FeeeSymbol(item.buyorsell,item.firstCurrency,item.secondCurrency),
                            ])
                        }else{
                            resultArr.push([
                                dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                                `${item.firstCurrency}/${item.secondCurrency}`,
                                capitalize(item.buyorsell),
                                item.filledQuantity == 0 ? '-' : Number(item.filledQuantity).toFixed(8),
                                Number(item.price).toFixed(8),
                                Number(item.orderValue).toFixed(8),
                                Number(item.Fees).toFixed(8) + " " + FeeeSymbol(item.buyorsell,item.firstCurrency,item.secondCurrency),
                            ])
                        }
                    })

                    const unit = "pt";
                    const size = "A4"; // Use A1, A2, A3 or A4
                    const orientation = "landscape"; // portrait or landscape

                    const marginLeft = 40;
                    const doc = new jsPDF(orientation, unit, size);

                    doc.setFontSize(13);

                    const title = "Spot Trade History";
                    const headers = [
                        [
                            "Date",
                            "Pair",
                            "Side",
                            "Filled",
                            "Price",
                            "Total",
                            "Fees"
                        ],
                    ]

                    let content = {
                        startY: 50,
                        head: headers,
                        body: resultArr,
                    };

                    doc.text(title, marginLeft, 40);
                    doc.autoTable(content);
                    doc.save("spotTradeHistory.pdf");
                }
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        let reqQuery = {
            page,
            limit
        }
        fetchHistory(reqQuery)
    }, [])
    
    return (
        <Fragment>
            <div className="order_header_">
                <div className="d-flex">
                </div>
                <div className="select_section">
                    <div className="tableSearchBox">
                        <div class="input-group">
                            <input
                                type="text"
                                name="search"
                                value={search}
                                onChange={handleChange}
                                class="form-control"
                                placeholder="Search"
                            />
                            <div class="input-group-append">
                                <span class="btnType1"><i class="fas fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="select_lable_">
                        <label>{t('PAIRS')}</label>
                        <Select
                            name="pairName"
                            label="- All coins -"
                            value={pairName}
                            onChange={handleChange}
                        >
                            <MenuItem value={'all'}>{t('ALL')}</MenuItem>
                            {
                                pairList && pairList.length > 0 && pairList.map((item, key) => {
                                    return (
                                        <MenuItem key={key} value={`${item.firstCurrencySymbol}${item.secondCurrencySymbol}`}>{item.firstCurrencySymbol}/{item.secondCurrencySymbol}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>

                    {/* <div className="seacr_box_s">
                        <input type="text" placeholder="Find Coin" />
                        <i class="fas fa-search"></i>
                    </div> */}
                    <div className="submit_btn w70_i p-0 ml-2">
                        <Button onClick={handlePDF} className="m-0">{t('Download_PDF')}</Button>
                    </div>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={data}
                noHeader
                pagination
                progressPending={loader}
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={count}
                onChangePage={handlePageChange}
            />
        </Fragment>
    )
}

export default TradeOrder;