// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {},
        lettersWithSpace = /^[A-Za-z ]+$/;

    if (isEmpty(value.bankName)) {
        errors.bankName = "REQUIRED"
    } else if (!lettersWithSpace.test(value.bankName)) {
        errors.bankName = "BANK_NAME_ALPHABET"
    }

    if (isEmpty(value.accountNo)) {
        errors.accountNo = "REQUIRED"
    }else if(value.accountNo.toString().length < 9 || value.accountNo.toString().length > 18){
        errors.accountNo = "MIN_MAX_ERR"
    }
    
    if (isEmpty(value.holderName)) {
        errors.holderName = "REQUIRED"
    } else if (!lettersWithSpace.test(value.holderName)) {
        errors.holderName = "HOLDER_NAME_ALPHABET"
    }/*else if((value.holderName.toString().length) < 6){
        errors.holderName = "HOLDER_NAME_ERR"
    }*/else if((value.holderName.toString().length) > 100){
        errors.holderName = "HOLDER_NAME_ERR"
    }

    if (isEmpty(value.bankcode)) {
        errors.bankcode = "REQUIRED"
    }else if(value.bankcode.toString().length != 11){
        errors.bankcode = "INVALID_IFSC_CODE"
    }


    if (isEmpty(value.country)) {
        errors.country = "REQUIRED"
    }

    if (isEmpty(value.city)) {
        errors.city = "REQUIRED"
    } else if (!lettersWithSpace.test(value.city)) {
        errors.city = "CITY_ALPHABET"
    }

    if (isEmpty(value.bankAddress)) {
        errors.bankAddress = "REQUIRED"
    }

    if (isEmpty(value.currencyId)) {
        errors.currencyId = "REQUIRED"
    }
    
    if(value.formType == 'add'){
        if (value.attachment == '' || value.attachment == undefined) {
            errors.attachment = "REQUIRED"
        }
    }

    return errors;
}

export default validation;