// import package
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux'
import { Button } from "@material-ui/core";
import browser from 'browser-detect';
import Checkbox from 'rc-checkbox';
import clsx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Bowser from "bowser"

// import action
import { getGeoInfoData, login } from '../../actions/users';

// import lib
import validation from './validation';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';
import { getLang } from '../../lib/localStorage';

const initialFormValue = {
    'email': '',
    'formType': '',
    'password': '',
    'twoFACode': '',
    'pinNumber': '',
    'showPassword': false,
    'remember': false
}

const EmailForm = () => {
    const { t, i18n } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const history = useHistory();
    const dispatch = useDispatch();

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [toched, setToched] = useState({});
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();
    const [loginHistory, setLoginHistory] = useState({});
    const [showTwoFA, setShowTowFA] = useState(false)
    const [showPinSecurity, setShowPinSecurity] = useState(false)

    const { email, showPassword, password, formType, remember, twoFACode, pinNumber } = formValue;

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        if (name == 'twoFACode' || name == 'pinNumber') {
            if (!(value == '' || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
                return
            }
        }

        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        setValidateError(validation(formData))
    }

    const handleBlur = (e) => {
        const { name } = e.target;
        setToched({ ...toched, [name]: true })
    }

    const handleCheckBox = (e) => {
        const { name, checked } = e.target
        let formData = { ...formValue, [name]: checked }
        setFormValue(formData)
        setValidateError(validation(formData))
    }

    const getGeoInfo = async () => {
        try {
            let { result } = await getGeoInfoData();
            const browserResult = browser();
            const Browser = Bowser.getParser(window.navigator.userAgent)
            setLoginHistory({
                countryName: result.country_name,
                countryCode: result.country_calling_code,
                ipaddress: result.ip,
                region: result.region,
                broswername: `${Browser.parsedResult.browser.name}${Browser.parsedResult.browser.version}`,
                ismobile: browserResult.mobile,
                os: Browser.parsedResult.os.name,
            })
        }
        catch (err) {
        }
    };

    const handleReCaptcha = async () => {
        try {
            if (!executeRecaptcha) {
                toastAlert('error', 'Recaptcha error')
                return '';
            }
            return await executeRecaptcha('register');
        } catch (err) {
            toastAlert('error', err.toString())
            return ''
        }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        let reCaptcha = await handleReCaptcha()
        if (isEmpty(reCaptcha)) {
            toastAlert('error', 'Invalid ReCaptcha', 'signin', 'TOP_CENTER');
            return
        }

        setLoader(true)
        let reqData = {
            email,
            password,
            remember,
            twoFACode,
            pinNumber,
            reCaptcha,
            loginHistory,
            langCode: getLang(),
            formType
        }

        let { status, loading, message, userSetting, error } = await login(reqData, dispatch);
        setLoader(loading);
        
        if (status == 'success') {
            setFormValue(initialFormValue)
            if (remember) {
                localStorage.setItem("remember", true);
                localStorage.setItem("email_remember", email);
                localStorage.setItem("password_remember", password);
                localStorage.setItem("formType", formType);
            } else {
                localStorage.removeItem("remember");
                localStorage.removeItem("email_remember");
                localStorage.removeItem("password_remember");
            }
            
            toastAlert('success', message, 'login');
            if (userSetting && userSetting.afterLogin && userSetting.afterLogin != "") {
                history.push(userSetting.afterLogin.url)
            } else {
                history.push('/instant-swap')
            }
        } else if (status == 'TWO_FA') {
            setShowTowFA(true)
            toastAlert('error', t(message), 'login');
        } else if (status == 'SECURITY_PIN') {
            setShowPinSecurity(true)
            toastAlert('error', t(message), 'login');
        } else {
            if (error) {
                let errors = {
                    'email' : '',
                    'password' : '',
                    'text' : '',
                }
                Object.keys(errors) && Object.keys(errors).length > 0 && Object.keys(errors).map((item)=> {
                    setToched((el) => {
                       return { ...el, ... {[item]:true} }
                    })
                })
                // setToched(Object.keys(error))
                setValidateError(error);
                return
            }
            toastAlert('error', message, 'login');
        }
    }

    useEffect(() => {
        getGeoInfo()
        console.log(browser(),'browserbrowser')
        let formData = {};
        if (localStorage.getItem("remember") == "true") {
            formData = formValue
            formData['email'] = localStorage.getItem("email_remember");
            formData['password'] = localStorage.getItem("password_remember");
            formData['remember'] = true;
            formData['formType'] = localStorage.getItem("formType");
            setFormValue(formData);
        } else {
            formData = {
                'email': '',
                'password': '',
                'twoFACode': '',
                'remember': false,
                'formType': 'email'
            }
            setFormValue(formData);
        }
        setValidateError(validation(formData))
    }, [])

    return (
        <Fragment>
            <div className="form-group">

                <span className="login_label">{t('EMAIL_PLACEHOLDER')}</span>
                <input
                    type="text"
                    className="form-control mt-2"
                    placeholder={t('EMAIL_PLACEHOLDER')}
                    name="email"
                    value={email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {toched.email && validateError.email && <p className="error-message">{t(validateError.email)}</p>}
                 {/* <span style={{ color: 'red' }}>{validateError && t(validateError.email)}</span>          */}
            </div> 
            <div className="form-group">
                <span className="login_label">{t('ENTER_PASSWORD')}</span>
                <div className="input-group regGroupInput mt-2">
                    <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={t('PASSWORD')}
                        name="password"
                        value={password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <div className="input-group-append">
                        <Link onClick={(e) => {
                            e.preventDefault();
                            setFormValue((el => {
                                return { ...el, ...{ showPassword: !el.showPassword } }
                            }))
                        }}>
                            <i className={clsx("fa", { "fa-eye": showPassword }, { "fa-eye-slash": !showPassword })} aria-hidden="true"></i>
                        </Link>
                    </div>
                </div>
                {toched.password && validateError.password && <p className="error-message">{t(validateError.password)}</p>}
            </div>

            {
                showTwoFA && <div className="form-group">
                    <input
                        type="text"
                        className="form-control mt-2"
                        placeholder={t('ENTER_TWO_FA_CODE')}
                        name="twoFACode"
                        value={twoFACode}
                        onChange={handleChange}
                    />
                    {validateError.twoFACode && <p className="error-message">{t(validateError.twoFACode)}</p>}
                </div>
            }

            {
                showPinSecurity && <div className="form-group">
                    <input
                        type="text"
                        className="form-control mt-2"
                        placeholder={t('ENTER_PIN_NUMBER')}
                        name="pinNumber"
                        value={pinNumber}
                        onChange={handleChange}
                    />
                    {validateError.pinNumber && <p className="error-message">{t(validateError.pinNumber)}</p>}
                </div>
            }



            <div className="form-group">
                <div className="form-check">
                    <Checkbox
                        name="remember"
                        onChange={handleCheckBox}
                        checked={remember}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                        {t('REMEMBER_ME')}
                    </label>
                </div>
            </div>
            <div className="form-group">

                <Button
                    onClick={handleFormSubmit}
                    disabled={!isEmpty(validateError) || loader}
                >
                    {loader && <i class="fas fa-spinner fa-spin"></i>} {t('SIGN_IN_BUTTON')}
                </Button>
            </div>
        </Fragment>
    )
}

export default EmailForm;