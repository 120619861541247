
// import package
import React from 'react';

// import component
 import ViewBankDetail from './ViewQRDetails';
 import EditBankDetail from './EditQRDetails';
 import ListBankDetail from './ListQRDetails';


const QRCodeAccount = () => {

    return (
        <div className="dashboard_box">

            <ListBankDetail />

             <EditBankDetail />

            <ViewBankDetail />


        </div>
    )
}

export default QRCodeAccount;