// import config
import axios, { handleResp } from '../config/axios'

// import actions
import { getAssetData } from './walletAction';

// import constant
import { SET_INSTANT_PAIRS } from '../constant'

export const getInstantPair = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/instant-exchange-pair`
        });

        dispatch({
            type: SET_INSTANT_PAIRS,
            data: respData.data.result
        })

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const orderPlace = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/instant-exchange`,
            data
        });
        
        getAssetData(dispatch)
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
            message: err.response.data.message,
        }
    }
}
export const getOrderHistory = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/instant-exchange`,
            params
        });
        
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}