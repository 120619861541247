// import package
import React from 'react';
import { Button } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import components
import MarketSlider from './MarketSlider';

const HomeBanner = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    var banner_videoslider = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,        
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,                
                }
            }
        ]
    };

    return (
        <>
            <section className="homeMainBannerContent">
                <div className="container">
                    <GridContainer className="align-items-center">
                        <GridItem md={8}>
                            <h1 data-aos="fade-up" data-aos-duration="2000"><b>EXCHANGE</b> is the easiest place to <span className="grn_clr">Buy</span> & <span className="yel_clr">Sell</span> Cryptocurrency.</h1>
                            <h5 data-aos="fade-up" data-aos-duration="2000"><span>Sign up and get started today.Join the world's largest crypto exchange.</span></h5>
                            <div data-aos="fade-up" data-aos-duration="2000" className="d-flex align-items-center">
                                <Link class="btn_bg mr-2" to="/register">Get Started</Link>
                                <Link class="bor_bg btn_bg" to="/spot">Trade</Link>
                            </div>
                        </GridItem>
                        <GridItem md={4}>
                            <img alt="" src={require("../../assets/images/img_5.png")} className="img-fluid" data-aos="fade-up" data-aos-duration="2000" />
                        </GridItem>
                    </GridContainer>
                </div>
            </section>

            <MarketSlider />

            {/*<section className="Market_Container">
                <div className="container">
                    <GridContainer>
                        <GridItem md={12}>
                            <div className="banner_ticker_panel">
                                <Slider {...tickersettings} className="ticker_slider">
                                    <div className="banner_ticker_single">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                            <h3>BTC / USDT</h3>
                                            <span class="badge green_badge">+1.25%</span>
                                            <h2 className="green_txt">$37,045.36</h2>
                                        </div>                                        
                                    </div>
                                    <div className="banner_ticker_single">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                            <h3>BTC / USDT</h3>
                                            <span class="badge green_badge">+1.25%</span>
                                            <h2 className="green_txt">$37,045.36</h2>
                                        </div>
                                    </div>
                                    <div className="banner_ticker_single">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                            <h3>BTC / USDT</h3>
                                            <span class="badge red_badge">+1.25%</span>
                                            <h2 className="red_txt">$37,045.36</h2>
                                        </div>                                        
                                    </div>
                                    <div className="banner_ticker_single">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                            <h3>BTC / USDT</h3>
                                            <span class="badge green_badge">+1.25%</span>
                                            <h2 className="green_txt">$37,045.36</h2>
                                        </div>
                                    </div>
                                    <div className="banner_ticker_single">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                            <h3>BTC / USDT</h3>
                                            <span class="badge red_badge">+1.25%</span>
                                            <h2 className="red_txt">$37,045.36</h2>
                                        </div>                                        
                                    </div>
                                    <div className="banner_ticker_single">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                            <h3>BTC / USDT</h3>
                                            <span class="badge red_badge">+1.25%</span>
                                            <h2 className="red_txt">$37,045.36</h2>
                                        </div>                                        
                                    </div>
                                </Slider>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>                
            </section>*/}

            {/*<section className="Top_Banners_Container">
                <div className="container">
                    <GridContainer>
                        <GridItem md={12}>
                            <div className="">
                                <Slider {...banner_videoslider} className="banner_videoslider">
                                    <div>                                        
                                        <video width="100%" height="240" autoPlay loop muted>
                                            <source src={require("../../assets/videos/bannerimg1.mp4")} type="video/mp4" />
                                            <source src={require("../../assets/videos/bannerimg1.ogg")} type="video/ogg" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div>                                        
                                        <video width="100%" height="240" autoPlay loop muted>
                                            <source src={require("../../assets/videos/bannerimg2.mp4")} type="video/mp4" />
                                            <source src={require("../../assets/videos/bannerimg2.ogg")} type="video/ogg" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div>                                        
                                        <video width="100%" height="240" autoPlay loop muted>
                                            <source src={require("../../assets/videos/bannerimg3.mp4")} type="video/mp4" />
                                            <source src={require("../../assets/videos/bannerimg3.ogg")} type="video/ogg" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div>                                        
                                        <video width="100%" height="240" autoPlay loop muted>
                                            <source src={require("../../assets/videos/bannerimg4.mp4")} type="video/mp4" />
                                            <source src={require("../../assets/videos/bannerimg4.ogg")} type="video/ogg" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div>                                        
                                        <video width="100%" height="240" autoPlay loop muted>
                                            <source src={require("../../assets/videos/bannerimg5.mp4")} type="video/mp4" />
                                            <source src={require("../../assets/videos/bannerimg5.ogg")} type="video/ogg" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div>                                        
                                        <video width="100%" height="240" autoPlay loop muted>
                                            <source src={require("../../assets/videos/bannerimg6.mp4")} type="video/mp4" />
                                            <source src={require("../../assets/videos/bannerimg6.ogg")} type="video/ogg" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </Slider>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>                
            </section>*/}
        </>
    )
}

export default HomeBanner;