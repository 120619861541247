// import package
import React, { useState, useEffect } from 'react';
import { Button, MenuItem, Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ConfirmOrder from './ConfirmOrder';

// import lib
import { toFixed } from '../../lib/roundOf'
import isEmpty from '../../lib/isEmpty';
import { completionValue } from '../../lib/calculation'
import { IncCntObjId } from '../../lib/generalFun'
import { useTranslation } from 'react-i18next';

const OrderList = (props) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    // props
    const { data, isAuth, refetch, orderList: { buyOrder, sellOrder }, search, setSearch, selCoin } = props;

    const { price, secondCoin, payBy } = search;

    // state
    const [payment, setPayment] = useState([])
    const [modal, setModal] = useState({
        show: false,
        record: {}
    })

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let query = { ...search, [name]: value }
        setSearch(query)
    }

    const closeModal = () => {
        setModal({
            show: false,
            record: {}
        })
    }

    const handleSearch = (e) => {
        e.preventDefault();
        let reqSearch = {};
        reqSearch['firstCoin'] = selCoin;
        if (!isEmpty(price)) reqSearch['price'] = price;
        if (!isEmpty(secondCoin) && secondCoin != 'all') reqSearch['secondCoin'] = secondCoin;
        if (!isEmpty(payBy) && payBy != 'all') reqSearch['payBy'] = payBy;
        refetch(reqSearch)
        // history.replace(`/p2p`)
    }

    const handleReset = (e) => {
        // e.preventDefault();
        let reqSearch = {};
        // alert(selCoin)
        reqSearch['firstCoin'] = selCoin;
        // reqSearch['secondCoin'] = 'all';
        // reqSearch['payBy'] = 'all';
        // if (!isEmpty(price)) reqSearch['price'] = price;
        // if (!isEmpty(secondCoin) && secondCoin != 'all') reqSearch['secondCoin'] = secondCoin;
        // if (!isEmpty(payBy) && payBy != 'all') reqSearch['payBy'] = payBy;
        refetch(reqSearch)
        setSearch({ ...search, 'price': '' })
        history.replace(`/p2p`)
    }

    useEffect(() => {
        if (data && data.pair && data.pair.length > 0) {
            if (secondCoin == 'all') {
                let allPayment = [...new Set(data.pair.flatMap(({ payment }) => payment))]
                setPayment(allPayment)
            } else {
                let secCoinDoc = data.pair.find(el => el.secondCoin == secondCoin)
                if (secCoinDoc) {
                    setPayment(secCoinDoc.payment)
                    if (payBy != 'all') {
                        let query = { ...search, 'payBy': secCoinDoc.payment[0] }
                        setSearch(query)
                    }
                }
            }
        }
    }, [data, secondCoin])

    return (
        <div>
            <ConfirmOrder
                show={modal.show}
                record={modal.record}
                onHide={closeModal}
            />
            <div className='p2p_filter_main'>
                <div className='orderBook'>
                    <ul class="nav nav-tabs">
                        <li><a class="green nav-link active" id="nav-p2pbuy-tab" data-toggle="tab" href="#nav-p2pbuy" role="tab" aria-controls="nav-p2pbuy" aria-selected="true">Buy</a></li>
                        <li><a class="nav-link red" id="nav-p2psell-tab" data-toggle="tab" href="#nav-p2psell" role="tab" aria-controls="nav-p2psell" aria-selected="false">Sell</a></li>
                    </ul>
                </div>

                <div className='p2p_filter'>
                    <div className="select_inbut">
                        <label>{t('ENTER_AMOUNT')}</label>
                        <div className="input_flex_section">
                            <div className="before_content">
                                <input
                                    type="text"
                                    name="price"
                                    value={price}
                                    onChange={handleChange}
                                    className="input_befor"
                                    placeholder='Enter Amount'
                                />
                            </div>
                            <Select
                                value={secondCoin}
                                name={'secondCoin'}
                                onChange={handleChange}
                                className="input_select input_diply_non"
                            >
                                <MenuItem value={'all'} >{t('ALL')}</MenuItem>
                                {
                                    data && data.pair && data.pair.length > 0 && data.pair.map((item, key) => {
                                        return (
                                            <MenuItem value={item.secondCoin}>{item.secondCoin}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="select_inbut">
                        <label>{t('PAY_BY')}</label>
                        <div className="input_flex_section">
                            <Select
                                value={payBy}
                                name={'payBy'}
                                onChange={handleChange}
                                className="input_select w-100 input_diply_non">
                                <MenuItem value={'all'} >{t('ALL_PAYMENT')}</MenuItem>
                                {
                                    payment && payment.length > 0 && payment.map((item, key) => {
                                        return (
                                            <MenuItem key={key} value={item}> {item}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="submit_btn simple_padind py-0 mr-3">
                        <Button onClick={handleSearch}>Search</Button>
                    </div>
                    <div className="submit_btn simple_padind py-0">
                        <Button onClick={handleReset}>Refresh</Button>
                    </div>
                </div>
            </div>

            <div class="tab-content mt-4" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-p2pbuy" role="tabpanel" aria-labelledby="nav-p2pbuy-tab">
                    <div className="table-responsive">
                        <table class="table table-striped table_buy_secion_sell">
                            <thead>
                                <tr>
                                    <th>Advertisers</th>
                                    <th>Per Price</th>
                                    <th>Available Quantity</th>
                                    <th>Available Limit</th>
                                    <th>Preferred Transfer</th>
                                    <th>Trade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    buyOrder && buyOrder.length > 0 && buyOrder.map((item, key) => {
                                    let filledQuantity = item.status != 'pending' ? item.filledQuantity : item.duplicateFilledQunatity

                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <div className="User_id_section">
                                                        <h5>{t('USER_ID')} {IncCntObjId(item.userId)}</h5>
                                                        <p>User Name : {`${item.firstName} ${item.lastName}`}, </p>
                                                        <p>{item.orderCnt} {t('TRADES')}, </p>
                                                        <span>{toFixed(completionValue(item.quantity, filledQuantity))}% {t('COMPLETION')}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_section">
                                                        <h5>{item.price} {item.secondCoin}</h5>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_section">
                                                        <p><span> {toFixed(item.remainingQty,8)} {item.firstCoin}</span></p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_section">
                                                        <p><span>  {toFixed(item.minLimit, 8)} {item.secondCoin} - {toFixed(item.maxLimit, 8)} {item.secondCoin} </span></p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_section text-capitalize">
                                                        <span>{item.payBy}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table_button">
                                                        <Button
                                                            onClick={() => {
                                                                if (isAuth) {
                                                                    setModal({
                                                                        'show': true,
                                                                        'record': item
                                                                    })
                                                                    return
                                                                }

                                                                history.push('/login')
                                                            }}
                                                        >
                                                            {t('BUY')}
                                                        </Button>
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                                {
                                    buyOrder && buyOrder.length == 0 && <div>{t('NO_RECORD')}</div>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-p2psell" role="tabpanel" aria-labelledby="nav-p2psell-tab">
                    <div className="table-responsive">
                        <table class="table table-striped table_buy_secion_sell">
                            <thead>
                                <tr>
                                    <th>Advertisers</th>
                                    <th>Per Price</th>
                                    <th>Available Quantity</th>
                                    <th>Available Limit</th>
                                    <th>Preferred Transfer</th>
                                    <th>Trade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sellOrder && sellOrder.length > 0 && sellOrder.map((item, key) => {
                                        let filledQuantity = item.status != 'pending' ? item.filledQuantity : item.duplicateFilledQunatity
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <div className="User_id_section">
                                                        <h5>{t('USER_ID')} {IncCntObjId(item.userId)}</h5>
                                                        <p>User Name : {`${item.firstName} ${item.lastName}`}, </p>
                                                        <p>{item.orderCnt} {t('TRADES')}, </p>
                                                        <span>{toFixed(completionValue(item.quantity, filledQuantity))}% {t('COMPLETION')}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_section">
                                                        <h5>{item.price} {item.secondCoin}</h5>                                                       
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_section">                                                       
                                                        <p><span> {toFixed(item.remainingQty,8)} {item.firstCoin}</span></p>                                                     
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_section">                                                       
                                                        <p><span> {toFixed(item.minLimit, 8)} {item.secondCoin}  - {toFixed(item.maxLimit, 8)} {item.secondCoin} </span></p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_section text-capitalize">                                                    
                                                        <span>{item.payBy}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table_button">
                                                        <Button
                                                            className="sell"
                                                            onClick={() => {
                                                                if (isAuth) {
                                                                    setModal({
                                                                        'show': true,
                                                                        'record': item
                                                                    })
                                                                    return
                                                                }

                                                                history.push('/login')
                                                            }}
                                                        >
                                                            {t('SELL')}
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    sellOrder && sellOrder.length == 0 && <div>{t('NO_RECORD')}</div>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {/* <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={6} className="paddin_right_o">
                    <h4 className="bye_sell_tlr text_grreni" >Buy</h4>

                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={6} className="paddin_left_o">
                    <h4 className="bye_sell_tlr text_reeed">{t('SELL')}</h4>

                </GridItem>
            </GridContainer> */}
        </div>
    )
}

export default OrderList