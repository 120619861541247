// import package
import React, { useState, useEffect, useContext } from 'react';
import { Button } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import Tabletabs from './Tabletabs'

// import context
import SocketContext from '../Context/SocketContext';

// import action
import { getMarketTrend } from '../../actions/homeAction'
import { toFixed } from '../../lib/roundOf'

const MarketTrend = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const socketContext = useContext(SocketContext);

    // state
    const [pairData, setPairData] = useState([])

    // function
    const fetchMarketTrend = async () => {
        try {
            const { status, loading, result } = await getMarketTrend();
            if (status == 'success') {
                setPairData(result)
            }
        } catch (err) { }
    }

    useEffect(() => {
        fetchMarketTrend();

        socketContext.socket.on('marketPrice', (result) => {
            setPairData((el) => {
                let pairList = [];
                el.map(item => {
                    if (item._id == result.pairId) {
                        pairList.push({
                            ...item,
                            "markPrice": toFixed(result.data.markPrice, 2),
                            "change": toFixed(result.data.change, 2)
                        })
                    } else {
                        pairList.push(item)
                    }
                })
                return pairList
            })
        })

        return () => {
            socketContext.socket.off("marketPrice");
        }
    }, [])


    return (
        <div className="explore pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center content-container">
                        <h1 className="mb-4 titlt_blue" data-aos="fade-up" data-aos-duration="1000">Trending Market Prizes</h1>
                        <div className="pb-4" data-aos="fade-up">                            
                            <div className="row">
                                {/* <div className="col-lg-6">
                                    <div className="mobile_sevrio">
                                        <img src={require("../../assets/images/mobile.png")} alt="logo" className="img-fluid" data-aos="zoom-out-right" data-aos-duration="1000" />
                                        <div className="submit_btn">
                                            <Button onClick={() => history.push('/spot')}>{t('TRADE')}</Button>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-12">
                                    <Tabletabs pairList={pairData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarketTrend;