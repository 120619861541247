// import package
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'classnames';

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import action
import { pinEnable, pinDisable } from '../../actions/users';

// import lib
import validation from './validation';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
    'newPin': '',
    'confirmPin': '',
    'loginPassword': '',
    'showNewPin': false,
    'showConfirmPin': false,
    'showLoginPassword': false
}

const SecurityPin = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    
    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState(false);

    const { newPin, confirmPin, loginPassword, showNewPin, showConfirmPin, showLoginPassword } = formValue;
    
    // redux-state
    const { pinStatus } = useSelector((state) => state.account);
    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        if(['newPin','confirmPin'].includes(name)){
            if (!(value == '' || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
                return
            }
        }
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        if (!isEmpty(validateError)) {
            setValidateError({})
        }
    }

    const enableSecurityPin = async (e) => {
        e.preventDefault();
        setLoader(true)
        let reqData = {
            newPin,
            confirmPin,
            loginPassword
        }
        // let validationError = validation(reqData)
        // if (!isEmpty(validationError)) {
        //     setValidateError(validationError)
        //     setLoader(false)
        //     return
        // }

        try {
            let { status, error, message } = await pinEnable(reqData,dispatch);
            setLoader(false)
            if (status == "success") {
                setFormValue(initialFormValue)
                toastAlert('success', t(message), 'pinEnable');
            } else {
                if (error) {
                    setValidateError(error);
                    return
                } else if (message) {
                    toastAlert('error', t(message), 'pinEnable');
                }
            }
        }
        catch (err) { }
    }


    const disableSecurityPin = async (e) => {
        e.preventDefault();
        setLoader(true)
        let reqData = {
            newPin,
            loginPassword
        }
        // let validationError = validation(reqData)
        // if (!isEmpty(validationError)) {
        //     setValidateError(validationError)
        //     setLoader(false)
        //     return
        // }

        try {
            let { status, error, message } = await pinDisable(reqData,dispatch);
            setLoader(false)
            if (status == "success") {
                setFormValue(initialFormValue)
                toastAlert('success', t(message), 'pinDisable');
            } else {
                if (error) {
                    setValidateError(error);
                    return
                } else if (message) {
                    toastAlert('error', t(message), 'pinDisable');
                }
            }
        }
        catch (err) { }
    }

    return (
        <div className="profileDetailView">
            {/* <h4>{t("UPDATE_PASSWORD")}</h4> */}
            <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <div className="twoFAForm">
                        <form className="contact_form mb-0">
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <div className="form-group">
                                        <label>Enter Your PIN</label>
                                        <div className="input-group regGroupInput mt-2">
                                            <input 
                                                type={showNewPin ? "text" : "password"} 
                                                className="form-control"
                                                name="newPin"
                                                value={newPin}
                                                onChange={handleChange}
                                            />
                                             <div className="input-group-append">
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    setFormValue((el => {
                                                        return { ...el, ...{ showNewPin: !el.showNewPin } }
                                                    }))
                                                }}>
                                                    <i className={clsx("fa", { "fa-eye": showNewPin }, { "fa-eye-slash": !showNewPin })} aria-hidden="true"></i>
                                                </Link>
                                            </div> 
                                        </div>
                                        {
                                            validateError.newPin && <p className="error-message">{t(validateError.newPin)}</p>
                                        }
                                    </div>
                                </GridItem>
                            {
                              !pinStatus &&    
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <div className="form-group">
                                        <label>Confirm Your PIN</label>
                                        <div className="input-group regGroupInput mt-2">
                                            <input 
                                                type={showConfirmPin ? "text" : "password"}
                                                className="form-control"
                                                name="confirmPin"
                                                value={confirmPin}
                                                onChange={handleChange}
                                            />
                                             <div className="input-group-append">
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    setFormValue((el => {
                                                        return { ...el, ...{ showConfirmPin: !el.showConfirmPin } }
                                                    }))
                                                }}>
                                                    <i className={clsx("fa", { "fa-eye": showConfirmPin }, { "fa-eye-slash": !showConfirmPin })} aria-hidden="true"></i>
                                                </Link>
                                            </div> 
                                        </div>
                                        {
                                            validateError.confirmPin && <p className="error-message">{t(validateError.confirmPin)}</p>
                                        }
                                    </div>
                                </GridItem>
                            }
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <div className="form-group">
                                        <label>Enter Your Login Password</label>
                                        <div className="input-group regGroupInput mt-2">
                                            <input 
                                                type={showLoginPassword ? "text" : "password"}
                                                className="form-control"
                                                name="loginPassword"
                                                value={loginPassword}
                                                onChange={handleChange}
                                            />
                                             <div className="input-group-append">
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    setFormValue((el => {
                                                        return { ...el, ...{ showLoginPassword: !el.showLoginPassword } }
                                                    }))
                                                }}>
                                                    <i className={clsx("fa", { "fa-eye": showLoginPassword }, { "fa-eye-slash": !showLoginPassword })} aria-hidden="true"></i>
                                                </Link>
                                            </div> 
                                        </div>
                                        {
                                            validateError.loginPassword && <p className="error-message">{t(validateError.loginPassword)}</p>
                                        }
                                    </div>
                                </GridItem>
                            </GridContainer> 
                        {
                            !pinStatus && <div className="form-group mb-0">
                                <button
                                    type="button"
                                    onClick={enableSecurityPin}
                                    className="btn btn-primary text-uppercase py-2"
                                >
                                    {loader && <i class="fas fa-spinner fa-spin"></i>}
                                    Enable
                                </button>
                            </div>
                        }

                        {
                            pinStatus && <div className="form-group mb-0">
                                <button
                                    type="button"
                                    onClick={disableSecurityPin}
                                    className="btn btn-primary text-uppercase py-2"
                                >
                                    {loader && <i class="fas fa-spinner fa-spin"></i>}
                                    Disable
                                </button>
                            </div>
                        }
                        </form>
                    </div>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={6} lg={6}>
                    <div className="settingsNote">
                        <h6>{t("NOTES")}</h6>
                        <p>{t("PASSWORD_TITLE")}</p>
                        <ul>
                            <li>- {t("PASSWORD_DESCRIPTION1")}</li>
                            <li>- {t("PASSWORD_DESCRIPTION5")}</li>
                            <li>- {t("PASSWORD_DESCRIPTION2")}</li>
                            <li>- {t("PASSWORD_DESCRIPTION6")}</li>
                            <li>- {t("PASSWORD_DESCRIPTION3")}</li>
                            <li>- {t("PASSWORD_DESCRIPTION4")}</li>
                        </ul>
                    </div>
                </GridItem> */}
            </GridContainer>
        </div>
    )
}

export default SecurityPin;