/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import component
import NewsSubscribe from '../NewsSubscribe';

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

// import action
import { getLanguage, getSocialMedia } from '../../actions/commonAction';

// import lib
import { capitalize } from '../../lib/stringCase';
import { setLang, getLang } from '../../lib/localStorage';
import isEmpty from "../../lib/isEmpty";
const useStyles = makeStyles(styles);

export default function BeforeLogin(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  // state
  const [langOption, setLangOption] = useState([])
  const [language, setLanguage] = useState('')
  const [socialMedia, setSocialMedia] = useState({})

  // redux-state
  const { isAuth } = useSelector(state => state.auth);

  // function
  const handleLanguage = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLanguage(value)
    setLang(value)
    i18n.changeLanguage(value);
  }

  const fetchLanguage = async () => {
    try {
      const { status, loading, result } = await getLanguage(dispatch);
      if (status == 'success') {
        setLangOption(result);
        let lang = getLang();
        if (isEmpty(lang)) {
          let primaryData = result && result.length > 0 && result.find((el => el.isPrimary == true))
          if (primaryData) {
            setLanguage(primaryData.code)
            setLang(primaryData.code)
            i18n.changeLanguage(primaryData.code);
          }
        } else {
          setLanguage(lang)
        }
      }
    } catch (err) { }
  }

  const fetchSocialMedia = async () => {
      let { status, result } = await getSocialMedia();

      if(status == 'success'){
         setSocialMedia(result)
      }
  }

  useEffect(() => {
    fetchLanguage();
    fetchSocialMedia();
  }, [])

  return (
    <footer className="">
    
      
      <div className="container">
        <div className="row">
          <div className="col-md-4 mr-3">
            <img src={require("../../assets/images/logo.png")} alt="logo" className="img-fluid mb-2" />
            <p className="footer_about">Exchange is one of the most trusted place for trading Cryptocurrency in India. We bring you the ability to buy and sell the variety of Cryptos on a secure, faster, simpler and uninterrupted way.</p>
            <ul className="list-inline d-flex social_media justify-content-center">
              <li><a href={socialMedia && socialMedia.facebookLink} target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href={socialMedia && socialMedia.twitterUrl} target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href={socialMedia && socialMedia.instaLink} target="_blank"><i className="fab fa-instagram"></i></a></li>
              <li><a href={socialMedia && socialMedia.youtubeLink} target="_blank"><i className="fab fa-youtube"></i></a></li>
              <li><a href={socialMedia && socialMedia.pinterestLink} target="_blank"><i className="fab fa-pinterest"></i></a></li>
              {/* <li><a href="javascript:void(0)"><i className="fab fa-telegram-plane"></i></a></li>
              <li><a href="javascript:void(0)"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="javascript:void(0)"><i className="fab fa-reddit-alien"></i></a></li> */}              
            </ul>
          </div>
          <div className="col-md-2">
            <h4 className="footer_form">About Us</h4>
            <ul className="footer_list">
              <li><Link to="/about">About</Link></li>
              {/*<li><Link to="/">Buy & Sell</Link></li>*/}
              <li><Link to="/contact">Contact Us</Link></li>
              {/*<li><Link to="/">Blog</Link></li>*/}
              {/*<li><Link to="/">Press</Link></li>*/}              
            </ul>
          </div>
          <div className="col-md-2">
            <h4 className="footer_form">Support</h4>
            <ul className="footer_list">
              <li><Link to="/terms">Terms & Conditions</Link></li>
              <li><Link to="/privacy-policy">Privacy policy</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/spot">Trade</Link></li>
              {/*<li><Link to="/">Api Documentation</Link></li>*/}                            
            </ul>
          </div>
          <div className="col-md-3">
            <NewsSubscribe />
          </div>
        </div>        
      </div>
      <div className="copy_right_div">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="copy_right">{t("COPY_RIGHT", { "YEAR": "2022" })}</p>
            </div>              
          </div>
        </div>
      </div>
    </footer>
  );
}

BeforeLogin.propTypes = {
  whiteFont: PropTypes.bool
};
