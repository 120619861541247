// import package
import React, { useState, useEffect, useContext } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Slider from "react-slick";

// import context
import SocketContext from '../Context/SocketContext';

// import action
import { getMarketTrend } from '../../actions/homeAction';
import { getPairList } from '../../actions/spotTradeAction';

// import lib
import { toFixed } from '../../lib/roundOf';


const tickersettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const MarketSlider = () => {


   const socketContext = useContext(SocketContext);

    // state
    const [pairData, setPairData] = useState([])

    // function
    // const fetchMarketTrend = async () => {
    //     try {
    //         const { status, loading, result } = await getMarketTrend();
    //         if (status == 'success') {
    //             setPairData(result)
    //         }
    //     } catch (err) { }
    // }

    const getData = async () => {
    	const { status, loading, result } = await getPairList();
    	if (status == 'success') {
            setPairData(result)
        }
    }	

    useEffect(() => {
        // fetchMarketTrend();
        getData();
       
        socketContext.socket.on('marketPrice', (result) => {
            setPairData((el) => {
                let pairList = [];
                el.map(item => {
                    if (item._id == result.pairId) {
                        pairList.push({
                            ...item,
                            "markPrice": toFixed(result.data.markPrice, 2),
                            "change": toFixed(result.data.change, 2)
                        })
                    } else {
                        pairList.push(item)
                    }
                })
                return pairList
            })
        })

        return () => {
            socketContext.socket.off("marketPrice");
        }
    }, [])
   
   return (
   	  <section className="Market_Container">
        <div className="container">
            <GridContainer>
                <GridItem md={12}>
                    <div className="banner_ticker_panel">
                        <Slider {...tickersettings} className="ticker_slider">
                        { pairData && pairData.length > 0 && pairData.map((item) => {

                        	return (
	                            <div className="banner_ticker_single">
	                                <div className="d-flex align-items-center justify-content-center">
	                                    <img src={item.firstCurrencyImage} alt="Icon"/>
	                                    <h3>{`${item.firstCurrencySymbol} / ${item.secondCurrencySymbol}`}</h3>
	                                    <span class={item.change <=0 ? "badge red_badge" : "badge green_badge" }>
	                                       {item.change}%
	                                    </span>
	                                    <h2 className={item.change <= 0 ? "red_txt" : "green_txt"}>{item.markPrice}</h2>
	                                </div>                                        
	                            </div>
                            )
                          })  
                        }    


                            {/*<div className="banner_ticker_single">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                    <h3>BTC / USDT</h3>
                                    <span class="badge green_badge">+1.25%</span>
                                    <h2 className="green_txt">$37,045.36</h2>
                                </div>
                            </div>
                            <div className="banner_ticker_single">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                    <h3>BTC / USDT</h3>
                                    <span class="badge red_badge">+1.25%</span>
                                    <h2 className="red_txt">$37,045.36</h2>
                                </div>                                        
                            </div>
                            <div className="banner_ticker_single">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                    <h3>BTC / USDT</h3>
                                    <span class="badge green_badge">+1.25%</span>
                                    <h2 className="green_txt">$37,045.36</h2>
                                </div>
                            </div>
                            <div className="banner_ticker_single">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                    <h3>BTC / USDT</h3>
                                    <span class="badge red_badge">+1.25%</span>
                                    <h2 className="red_txt">$37,045.36</h2>
                                </div>                                        
                            </div>
                            <div className="banner_ticker_single">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={require("../../assets/images/btcIcon.png")} alt="Icon"/>
                                    <h3>BTC / USDT</h3>
                                    <span class="badge red_badge">+1.25%</span>
                                    <h2 className="red_txt">$37,045.36</h2>
                                </div>                                        
                            </div>*/}
                        </Slider>
                    </div>
                </GridItem>
            </GridContainer>
        </div>                
    </section>
   )
}

export default MarketSlider;